<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    class="event-view-modal"
    :title="null"
    v-if="selectedEvent"
  >
    <div class="event-view-modal-container">
      <div
        class="event-view-modal-container__header"
        :style="{
          backgroundImage: `url(${selectedEvent.files[0].file})`,
        }"
      >
        <a-dropdown v-if="selectedEvent.permission ==='organizer'" @click.stop :trigger="['click']" placement="bottomRight">
          <a class="event-view-modal-container__header--dropdown">
            <i class="icon icon-vertical_three-dots"></i>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item key="1" @click="openEditModal">
                <a>Edit</a>
              </a-menu-item>
              <a-menu-item key="2" @click="closeModal()">
                <a>Close</a>
              </a-menu-item>
              <a-menu-item key="3">
                <a>Copy link</a>
              </a-menu-item>
              <a-menu-item v-if="!selectedEvent.permission === 'organizer'" key="4">
                <a class="remove-btn">
                  Report this event
                </a>
              </a-menu-item>
              <a-menu-item key="5">
                <a class="remove-btn" @click="this.deleteEventModal=true">
                  Remove
                </a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="event-view-modal-container__body">
        <h2 class="event-view-modal-container__body--title">
         {{ selectedEvent.title }}
        </h2>
        <p class="event-view-modal-container__body--event-by">
          Event by <a>{{ selectedEvent.user.full_name }}</a>
        </p>
        <div class="event-view-modal-container__body--time">
          <img src="@/assets/icons/calendar-icon.png" />
          <p>
            {{ formatDate(selectedEvent.schedule.lower)}} - {{ formatDate(selectedEvent.schedule.upper)}} ({{ selectedEvent.timezone }})
          </p>
        </div>
        <div class="event-view-modal-container__body--event-type">
          <img src="@/assets/icons/video-camera-icon.png" />
          <p>{{ selectedEvent.event_type === 'online' ? 'Online' : 'In person' }}</p>
        </div>
        <div class="event-view-modal-container__body--event-link" v-if="selectedEvent?.link">
          <img src="@/assets/icons/new-tab-icon.png" />
          <p><a :href="selectedEvent?.link" target="_blank">{{ selectedEvent?.link }}</a></p>
        </div>

        <!-- <div class="event-view-modal-container__body--attendees">
          <p>36,474 attendees</p>
        </div> -->
        <div class="event-view-modal-container__body--footer">
          <a-button 
            v-if="selectedEvent.permission != 'organizer'" 
            :loading="isRegister" 
            @click="eventRegistration(selectedEvent.permission)" 
            :class="`btn ${selectedEvent.permission === 'attendee' ? 'btn-warn' : ''}`">
            {{ selectedEvent.permission === 'attendee' ? 'Leave' : 'Register' }}
          </a-button> 
        </div>
      </div>
    </div>
  </a-modal>
  <delete-modal
    :visible="deleteEventModal"
    @handle-close="deleteEventModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="isRegister"
    @delete-record="deleteEvent"
  ></delete-modal>
  <CreateEventModal
    :visible="visibleCreateEventModal"
    @closeModal="closeCreateEventModal"
    :singleEvent="event"
  />
</template>
<script>
import CreateEventModal from "./CreateEventModal.vue";
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import moment from 'moment';
export default {
  data() {
    return {
      visibleCreateEventModal: false,
      deleteEventModal: false,
      event: null,
      showMessageModal:false,
      isRegister:false,
      message: {},
    };
  },
  props: {
    visible: Boolean,
  },
  components: {
    CreateEventModal,
    DeleteModal,
  },
  computed: {
    selectedEvent() {
    return this.$store.getters["profile/selectedEvent"]
    },
  },
  methods: {
    // copyEventLink() {
    //   this.copiedLink = true;
    //   setTimeout(() => {
    //     this.copiedLink = false;
    //   }, 2000);
    //   const eventUrl = `${process.env.VUE_APP_REDIRECTION_URL}/user/${this.userId}?tab=experience`;
    //   navigator.clipboard.writeText(eventUrl);
    // },
    async getAllEvents() {
      try {
        this.loadingEvents = true;
        const payload = {
        user_id: this.$route.params.id,
        page: 1,
        page_size: 8,
      };
        await this.$store.dispatch("profile/getAllEvent", {
          user_id:this.$route.params.id,
          payload: payload
        });
      } catch (error) {
        console.log('error',error);
      } finally{
        this.loadingEvents = false;
      }
    },
    formatDate(date) {
      return moment(date).format('MMMM D, YYYY [at] h:mm A');
    },
    closeModal() {
      this.$store.dispatch('profile/resetEvent');
      this.$emit("closeModal");
    },
    closeCreateEventModal() {
      this.visibleCreateEventModal = false;
    },
    openEditModal() {
      this.visibleCreateEventModal = true;
      this.$emit("closeModal");
    },
    async eventRegistration(permission) {
  this.isRegister = true;
  try {
    // Determine the action based on the permission
    const action = permission === "attendee" ? "leaveEvent" : "joinEvent";
    const successMessage = permission === "attendee"
      ? "You have successfully left the event."
      : "Congratulations! You are now successfully registered to attend the event.";

    // Dispatch the store action
    const res = await this.$store.dispatch(`profile/${action}`, {
      event_id: this.selectedEvent.id
    });

    // Check for successful response
    if (res.status === 200) {
      await this.getAllEvents();
      this.closeModal();
      this.showMessageModal = true;
      this.message = {
        title: successMessage,
      };
    }
  } catch (error) {
    console.error('Error during event registration:', error);
    // Optionally handle the error here (e.g., show an error message to the user)
  } finally {
    this.isRegister = false;
  }
    },

    async deleteEvent(){
      try {
        this.isRegister=true;
        const res = await this.$store.dispatch("profile/deleteEvent", {
          event_id: this.selectedEvent.id
      });
      if(res.status===204){
        await this.getAllEvents();
        this.deleteEventModal = false;
        this.closeModal();
      }
      } catch (error) {
        console.log('error',error)
      } finally{
        this.isRegister=false;
      }
    },
  },
};
</script>

<style lang="scss">
.event-view-modal {
  width: 80rem !important;
  top: 4rem !important;
  .ant-modal-content {
    box-shadow: 0px 0px 94px #00000047;
    .ant-modal-close {
      display: none !important;
    }
    .ant-modal-body {
      .event-view-modal-container {
        background-color: $color-light-grey-2;
        border-radius: 1rem;
        border-end-end-radius: 1rem;
        border-end-start-radius: 1rem;
        &__header {
          height: 40rem;
          background-size: 100%, 100%;
          background-position: center;
          background-repeat: no-repeat;
          border-start-end-radius: 1rem;
          border-start-start-radius: 1rem;
          background-color: #f1f1f1;
          &--dropdown {
            background-color: #fff;
            position: absolute;
            top: 2.5rem;
            right: 2.5rem;
            width: 3rem;
            height: 3rem;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              color: $color-black;
              font-size: 1.2rem;
            }
          }
        }
        &__body {
          padding: 3.2rem;
          border: 1px solid $color-dark-grey-5;
          border-top: none;
          border-end-start-radius: 1rem;
          border-end-end-radius: 1rem;
          &--title {
            font-size: 2.6rem;
            font-family: $font-primary-medium;
            margin-bottom: 2rem;
            line-height: 3.4rem;
            color: $color-black;
          }
          &--event-by {
            display: flex;
            align-items: center;
            font-size: 1.8rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 2rem;
            color: $color-black;
            margin-bottom: 1.5rem;
            a {
              font-family: $font-primary-medium;
              color: $color-primary;
              cursor: pointer;
              transition: all 0.2s ease-in-out;
              margin-left: 0.8rem;
              &:hover {
                opacity: 0.8;
              }
            }
          }
          &--time {
            img {
              margin-top: 0.1rem;
              margin-left: -0.3rem;
            }
          }
          &--time,
          &--event-type,
          &--attendees,
          &--event-link {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-bottom: 1.5rem;
            img {
              width: 2.4rem;
              opacity: 0.7;
            }
            p {
              font-size: 1.6rem;
              font-family: $font-primary;
              margin-bottom: 0;
              line-height: 2rem;
              color: $color-black;
              a {
                color: $color-primary;
                transition: all 0.2s ease-in-out;
                &:hover {
                  opacity: 0.8;
                }

              }
            }
          }
          &--event-type {
            img {
              width: 2rem;
              margin-right: 0.2rem;
            }
          }
          &--event-link {
            img {
              width: 1.9rem;
              margin-right: 0.3rem;
            }
            p {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1; /* number of lines to show */
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
          &--footer {
            margin-top: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1.5rem;
            .btn {
              width: fit-content;
              height: 4rem;
              font-size: 1.4rem;
              padding: 0 3rem;
              border: none;
              outline: none;
              background-color: $color-primary;
              color: $color-white;
              font-family: $font-primary-medium;
              border-radius: 1rem !important;
              cursor: pointer;
            }
            .btn-warn {
              width: fit-content;
              height: 4rem;
              font-size: 1.4rem;
              padding: 0 3rem;
              border: none;
              outline: none;
              background-color: red;
              color: $color-white;
              font-family: $font-primary-medium;
              border-radius: 1rem !important;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
