<template>
  <div class="organization-location-section">
    <div class="organization-location-section__header">
      <h2 class="organization-location-section__header--title">Locations</h2>
      <button
        class="organization-location-section__header--btn"
        v-if="isLoggedInUser"
        @click="toggleOrganizationLocationModal()"
        id="addLocation"
        title="addLocation"
      >
        <i class="icon icon-add_plus"></i>
      </button>
    </div>
    <div class="organization-location-section__body">
      <experience-loader v-if="fetchingLocations"></experience-loader>
      <template v-else>
        <div
          class="organization-location-section__body--location"
          v-for="location in organizationLocations"
          :key="location"
        >
        <div class="header">
          <div class="header__details">
            <h2>{{ location.name }}</h2>
            <p>
              {{ location.address }}
            </p>
            <h4 v-if="location?.contact">
              <span>Contact:</span> {{ location?.contact }}
            </h4>
          </div>
          <div class="header__edit-delete-btn">
            <button
              v-if="isLoggedInUser"
              @click="showDeleteModal(location.id)"
              class="btn delete-btn"
              id="locationDeleteBtn"
              title="locationDeleteBtn"
            >
              <i class="icon icon-dustbin"></i>
            </button>
            <button
              v-if="isLoggedInUser"
              @click="toggleOrganizationLocationModal(true, location)"
              class="btn edit-btn"
              id="locationEditBtn"
              title="locationEditBtn"
            >
              <img src="@/assets/icons/edit-icon.svg" width="auto" height="auto" alt="Edit"/>
            </button>
          </div>
        </div>
      </div>
      </template>
      <p
        v-if="!fetchingLocations && organizationLocations.length == 0"
        class="message"
      >
        No location added
      </p>
    </div>
  </div>
  <OrganizationLocationsModal
    :locationDetails="locationDetails"
    :isEdit="isEdit"
    :buttonLoader="buttonLoader"
    :visible="showOrganizationLocationModal"
    @close-modal="showOrganizationLocationModal = false"
    @add-organization-location="addUpdateLocation"
    :footer="null"
  />
  <delete-modal
    :visible="deleteModalVisible"
    @handle-close="deleteModalVisible = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :message="null"
    @delete-record="deleteLocation"
    :loading="deleteLoading"
  ></delete-modal>
</template>

<script>
import ExperienceLoader from "../SkeletonLoaders/ExperienceSkeletonLoader.vue";
import DeleteModal from "../../BaseComponents/DeleteModal.vue";
import OrganizationLocationsModal from "./OrganizationLocationsModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      showOrganizationLocationModal: false,
      deleteLoading: false,
      deleteModalVisible: false,
      showMessageModal: false,
      message: {},
      locationDetails:{
        name: '',
        address: '',
        contact: ''
      },
      buttonLoader: false,
      isEdit:false,
      fetchingLocations: false
      
    };
  },
  components: {
    ExperienceLoader,
    OrganizationLocationsModal,
    DeleteModal,
  },
  computed: {
    ...mapGetters({
      organizationLocations: 'profile/organizationLocations'
    }),
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
  },
  async created(){
    await this.fetchOrganizationLocation()
  },
  methods: {
    ...mapActions({
      addOrganizationLocation: 'profile/addOrganizationLocation',
      deleteOrganizationLocation: 'profile/deleteOrganizationLocation',
      updateOrganizationLocation: 'profile/updateOrganizationLocation',
      fetchOrganizationLocation : 'profile/fetchOrganizationLocation'
    }),
    async addUpdateLocation(organizationLocation) {
      try{
        this.buttonLoader = !this.buttonLoader
        this.isEdit ? await this.updateOrganizationLocation(organizationLocation) : await this.addOrganizationLocation(organizationLocation)
        this.showOrganizationLocationModal = !this.showOrganizationLocationModal
        this.message = {
          title: this.isEdit ? "Location details updated successfully" : "New location added successfully",
          type: "success",
        };
        this.showMessageModal = true;
      }
      catch(err){
        this.showOrganizationLocationModal = !this.showOrganizationLocationModal
        this.message = {
          title: "Something went wrong",
          desc: "Please Try Again later. Sorry for the inconvenience",
          type: "failure",
        };
        this.showMessageModal = true;
      }
      finally{
        this.buttonLoader = !this.buttonLoader
      }
      
    },
    toggleOrganizationLocationModal(isEdit=false, location={}){
      this.isEdit = isEdit
      this.locationDetails = {...location}
      this.showOrganizationLocationModal = !this.showOrganizationLocationModal
    },
    
    async deleteLocation(e){
      try{
        this.deleteLoading = !this.deleteLoading
        await this.deleteOrganizationLocation({locationId: this.selectedRecordId})
        this.deleteModalVisible = false;
        this.message = {
          title: "Location removed successfully.",
          type: "success",
        };
        this.showMessageModal = true;
      }
      catch(err){
        this.message = {
          title: "Something went wrong",
          desc: "Please Try Again later. Sorry for the inconvenience",
          type: "failure",
        };
        this.showMessageModal = true;
        console.log(err)
      }
      finally{
        this.deleteLoading = !this.deleteLoading
      }
    },
    showDeleteModal(id) {
      this.selectedRecordId = id;
      this.deleteModalVisible = true;
      this.deleteLoading = false;
    },
  },
};
</script>

<style lang="scss">
.organization-location-section {
  padding: 3rem 2.6rem;
  border-top: 1px solid $color-dark-grey-5;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.7rem;
    &--title {
      font-size: 2rem;
      font-family: $font-primary-medium;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      @include respond(tab-port) {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }
    &--btn {
      background-color: $color-primary;
      height: 4.2rem;
      width: 4.2rem;
      border: none;
      outline: none;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon {
        color: $color-white;
        font-size: 1.6rem;
      }
    }
  }
  &__body {
    &--location {
      padding: 2.4rem 0 0;
      .header {
        display: flex;
        align-items: flex-start;
        &__details {
          width: 100%;
          padding-right: 2rem;
          h2 {
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            line-height: 1.8rem;
            color: $color-black;
            margin-bottom: 1rem;
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
          p {
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 1.8rem;
            color: #6f6f6f;
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
          h4 {
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 1.8rem;
            color: #6f6f6f;
            margin-top: 0.5rem;
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
            span {
              color: $color-black;
            }
          }
        }
        &__edit-delete-btn {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          .btn {
            line-height: normal;
            width: fit-content;
            height: fit-content;
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            img {
              width: 2.5rem;
              height: auto;
            }
            .icon {
              color: $color-black;
              font-size: 1.8rem;
              display: block;
            }
            @include respond(tab-port) {
              img {
                width: 3rem;
              }
              .icon {
                font-size: 2.3rem;
              }
            }
          }
        }
      }
      .body {
        ul {
          padding-left: 1.4rem;
          margin-top: 1.5rem;
          margin-bottom: 2rem;
          li {
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 1.9rem;
            color: $color-black;
            padding-left: 0;
            max-width: 90%;
            button {
              color: #6f6f6f;
              border: none;
              outline: none;
              background-color: transparent;
              cursor: pointer;
            }
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
        }
      }
    }
    .message {
      font-size: 1.6rem;
      font-family: $font-primary;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      margin-bottom: 3rem;
    }
  }
}
</style>
