import { mapActions } from 'vuex'
export default {
    data : ()=>({
        // selectedArchiveContentType: "articles",
    }),
    methods:{
        ...mapActions({
            fetchArchiveFoldersContentList: "archive/fetchArchiveFoldersContentList",
            emptyArchiveContentList: 'archive/emptyArchiveContentList',
            toggleArchiveContentLoader: 'archive/toggleArchiveContentLoader',
            toggleArchiveArticleLoader: 'archive/toggleArchiveArticleLoader',
            fetchArchiveSuggestedArticles: 'archive/fetchArchiveSuggestedArticles',
            fetchFolderArticles: 'folders/fetchFolderArticles',
            fetchFolderFiles: 'folders/fetchFolderFiles',
            fetchFolderMedia: 'folders/fetchFolderMedia',
            fetchFolderSuggestedArticles: 'folders/fetchFolderSuggestedArticles',
            fetchFolderBooks: 'folders/fetchFolderBooks'
        }),

          async getSelectedContentTypeContentList({archiveFolderId, contentType, userId='', emptyPreviousContent=false, searchTopic=''}){
            if(emptyPreviousContent)
                this.emptyArchiveContentList()
            else {
                contentType === 'articles' || contentType === 'suggested'? this.toggleArchiveArticleLoader() : this.toggleArchiveContentLoader()
                contentType === 'suggested' ? await this.fetchArchiveSuggestedArticles(searchTopic) : await this.fetchArchiveFoldersContentList({ archiveFolderId, contentType, userId});
                contentType === 'articles' || contentType === 'suggested' ? this.toggleArchiveArticleLoader() : this.toggleArchiveContentLoader()
            }
        },

        async getSelectedFolderContent({folderId, contentType, userId='', searchTopic}){
            if (contentType === 'articles'){
                this.toggleArchiveArticleLoader()
                await this.fetchFolderArticles({folderId, contentType:'article'})
                this.toggleArchiveArticleLoader()
            }
            else if (contentType === 'videos'){
                await this.fetchFolderMedia({folderId, contentType:'video'})
            }
            else if (contentType === 'podcast'){
                await this.fetchFolderFiles({folderId, contentType:'podcast'})
            }
            else if (contentType === 'suggested'){
                this.toggleArchiveArticleLoader()
                await this.fetchFolderSuggestedArticles(searchTopic)
                this.toggleArchiveArticleLoader()
            }
            else if (contentType === 'books'){
                await this.fetchFolderBooks({folderId, contentType:'book'})
            }



        }
    }
}