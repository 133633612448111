<template>
  <div class="archive-management-sidebar">
    <div class="archive-management-sidebar__header">
      <div class="archive-management-sidebar__header--name">
        <h3>Library</h3>
      </div>
    </div>
    <div class="archive-management-sidebar__body">
      <div class="list">
        <template v-if="archiveFoldersLoader">
          <a-skeleton
            v-for="(skeleton, index) in 5"
            :key="index"
            class="folder-skeleton"
            active
            :title="null"
            :paragraph="{
              rows: 1,
              width: ['100%'],
            }"
          />
        </template>
        <div class="message" v-if="!archiveFoldersLoader && archiveFolders.length === 0">
          <p>No public folders found.</p>
        </div>
        <template v-if="!archiveFoldersLoader && archiveFolders.length > 0">
            <a-tree 
              show-icon
              block-node
              :tree-data="archiveFolders"
              @drop="onDrop"
              v-model:expandedKeys="expandedKeys"
            >
            <template #folderIcon>
              <img src="@/assets/icons/glocal-folder-icon.svg" alt="Icon" />
            </template>
            <template #title="{ title, id, ...folder  }">
              <span class="title" @click.stop="selectItem(folder.dataRef, id)" :class="{'active': activeIndex === id}"><span>{{ title }}</span></span>
          </template>
            </a-tree>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import archiveMixin from "../../../mixins/archive.mixin";

export default {
  mixins: [archiveMixin],
  data() {
    return {
      activeIndex: null,
      activeKey: "public",
      selectedFolderName: "",
      folderId: "",
      visibleCategoryFolderModal: false,
      showFolderDeleteModal: false,
      loader: false,
      isEditing: false,
      showMessageModal: false,
      message: {},
      categoryToBeUpdateOrder: '',
      parentId: '',
      expandedKeys: [],
      duplicateFolderList: [],
      isSubCategory:false
    };
  },
  computed: {
    ...mapGetters({
      archiveFolderList: "archive/archiveFolderList",
      archiveFoldersLoader: "folders/getFolderLoader",
      isLoggedInUser: 'profile/isLoggedinUser',
      selectedArchiveContentType: 'archive/selectedArchiveContentType',
      selectedUser: 'profile/selectedUser',
      archiveContentCurrentPage: 'archive/archiveContentCurrentPage',
      folderList: 'folders/getFolderList',
      
    }),
    archiveFolders:{
      get() {
        const folderList = this.folderList.map(folder=>({
          key:folder.id,
          title: folder.name,
          slots: { icon: "folderIcon" },
          children:folder.child ? folder.child.map(child=>({
            key:child.id,
            title:child.name,
            slots: { icon: "folderIcon" },
            children:child.child ? child.child.map(grandChild=>({
              key:grandChild.id,
              title:grandChild.name,
              slots: { icon: "folderIcon" },
              children: [],
              ...grandChild
            })) : [],
            ...child
          })) : [],
          ...folder
        }))
        return folderList.sort((a,b)=>a.order-b.order)
      },
      // async set(folders) {

      //   folders.map(folder=>folder.child)

      //   let orderFolder = null
      //   let orderFolderIndex = null
      //   let childParent = null

      //   folders.forEach((folder, index)=>{
      //     if (folder.id === this.categoryToBeUpdateOrder){
      //       orderFolder = {...folder}
      //       orderFolderIndex = index
      //     }
      //     else{
      //       folder.children.forEach((child, index, array)=>{
      //         if (child.id === this.categoryToBeUpdateOrder){
      //             orderFolder = {...child}
      //             orderFolderIndex = index
      //             childParent = folder?.id
      //         }
      //       })
      //     }
      //   })
      //   try{
         
      //     if (orderFolder){
      //       await this.updateArhiveCategoriesOrdering({folderId: orderFolder?.id, order:orderFolderIndex +1, parent:childParent})
      //       this.$store.commit('archive/SET_ARCHIVE_FOLDER_LIST', folders);
      //       this.$showToast({message:'Category position updated successfully.', type:'success'})
      //       // this.showMessageModal = true
      //       // this.message = {
      //       //   title: "Category position updated successfully.",
      //       //   type: "success",
      //       // };
      //     }
      //   }
      //   catch(err){
      //     console.log(err)
      //   }
      // }
    },
  },
  watch: {
    // activeKey(val) {
    //   if (val === "public")
    //     this.fetchArchiveFoldersList({ visibility: "public", user_id: this.selectedUser.id });
    //   else if (val === "private")
    //     this.fetchArchiveFoldersList({
    //       visibility: "private",
    //       user_id: this.selectedUser.id,
    //     });
    // },
    // By default, the sidebar will show the public folders and active the first one
    archiveFolders(folder) {
      if (folder.length > 0) {
        this.activeIndex = folder[0].id;
        this.setSelectedFolder(folder[0]);
        if(this.archiveContentCurrentPage > 1){
          //Reset the pagination whenever preselecting the first folder after switching between public and private tabs
          this.updateArchiveContentCurrentPage('move-first-page')  // updating pagination triggers the fetching of articles
        }
        else{
          // if pagination is already set to 1 it will not trigger the watch function of over current page thus fetching the content manaully
          this.getSelectedFolderContent({
            folderId: folder[0].id,
            contentType: 'articles',
            userId: this.selectedUser.id,
          })
        }
      } else {
        this.activeIndex = null;
        this.emptyArchiveContentList()
        this.emptySelectedArchiveFolder()
      }

      this.expandedKeys = folder.flatMap(folder=>[folder?.key, ...folder.children.flatMap(child=>[child?.key, ...child.children.map(nestedChild=>nestedChild.key)])])
      this.selectedKeys = this.expandedKeys[0]
    },
    folderList(folder){
      console.log("FOLDER LIST", folder)
    }
  },
  async created(){
      await this.getFolderTree('public_folders')

    // this.emptyArchiveContentList()
    // this.emptySelectedArchiveFolder()
  },
  methods: {
    ...mapActions({
      fetchArchiveFoldersList: "archive/fetchArchiveFoldersList",
      archiveFolderCreate: "archive/archiveFolderCreate",
      saveArticleToArchiveFolder: "archive/saveArticleToArchiveFolder",
      setSelectedFolder: "archive/setSelectedFolder",
      emptyArchiveContentList: 'archive/emptyArchiveContentList',
      emptySelectedArchiveFolder :'archive/emptySelectedArchiveFolder',
      updateArchiveContentCurrentPage: 'archive/updateArchiveContentCurrentPage',
      updateArhiveCategoriesOrdering: 'archive/updateArhiveCategoriesOrdering',
      getFolderTree: 'folders/getFolderTree'
    }),
    selectItem(folder, index) {
      this.activeIndex = index;
      this.setSelectedFolder(folder);
      if(this.archiveContentCurrentPage > 1){
          //Reset the pagination whenever switching between folders
          this.updateArchiveContentCurrentPage('move-first-page')  // updating pagination triggers the fetching of articles
      }
      else{
        if ( this.selectedArchiveContentType !== 'suggested'){
          this.getSelectedFolderContent({
            folderId: folder.id,
            contentType: 'articles',
            userId: this.selectedUser.id,
          })
        }else{
          this.getSelectedFolderContent({
                contentType: this.selectedArchiveContentType,
                searchTopic: folder?.name,
            })
        }
      }
    },
    addNewCategory(parentId='') {
      this.selectedFolderName = "";
      this.folderId = "";
      this.isEditing = false;
      this.visibleCategoryFolderModal = true;
      this.parentId=parentId
      this.isSubCategory = parentId ? true :  false
    },
    editFolder(folder) {
      this.selectedFolderName = folder.name;
      this.folderId = folder.id;
      this.isEditing = true;
      this.visibleCategoryFolderModal = true;
    },
    async handleSave(payload) {
      try {
        this.loader = true;
        const requestPayload = {
          name: payload.folderName,
          id: payload.id || null,
        };
        if (this.isEditing) {
          const response = await this.$store.dispatch(
            "archive/updateArchiveFolderName",
            requestPayload
          );
          if (response.status === 200) {
            this.visibleCategoryFolderModal = false;
            // this.showMessageModal = true;
            this.loader = false;

            this.$showToast({message:'Category updated successfully.', type:'success'})
            // this.message = {
            //   title: "Category Edit Successfully",
            //   type: "success",
            // };
          }
        } else {
          try {
            this.loader = true;
            const requestPayload = {
              name: payload.folderName,
              visibility: this.activeKey,
            };

            if(this.parentId){
              requestPayload.parent = this.parentId
            }

            await this.archiveFolderCreate(requestPayload)
            this.visibleCategoryFolderModal = false;
            this.showMessageModal = true;
            this.loader = false;
            this.$showToast({message:'Category has been added successfully.', type:'success'})
            // this.message = {
            //   title: "Category Add Successfully",
            //   type: "success",
            // };
          } catch (err) {
            console.log(err);
            // this.showMessageModal = true;
            this.loader = false;
            // this.message = {
            //   title: "Category Add Failed",
            //   type: "failure",
            // };

            this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
            return;
          }
        }
      } catch (err) {
        console.log(err);
        this.loader = false;
      }
     
    },
    showdeleteFolder(folder) {
      this.folderId = folder.id;
      this.showFolderDeleteModal = true;
    },
    async deleteFolder() {
      try {
        this.loader = true;
        const response = await this.$store.dispatch(
          "archive/deleteArchiveFolder",
          this.folderId
        );
        if (response.status === 204) {
          this.showFolderDeleteModal = false;
          this.loader = false;
          this.$showToast({message:'Category removed successfully', type:'success'})
        }
      } catch (err) {
        console.log(err);
        // this.showMessageModal = true;
        this.loader = false
        // this.message = {
        //   title: "Failed to remove category.",
        //   type: "failure",
        // };
        this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
      }
    },
    onUpdate(newOrder){
      this.categoryToBeUpdateOrder = newOrder.id;
    },
    onDrop (info) {
      console.log(info)
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
      let isValid = true
      console.log(dropKey, dragKey, dropPos, dropPosition)
      // const dragNodesKeys = info.dragNodesKeys;
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr)
          }
        if (item.children) {
          return loop(item.children, key, callback);
        }
        })
      }
      let data = JSON.parse(JSON.stringify(this.archiveFolders))
      let dragObj = ''
         loop(data, dragKey, (item, index, arr) => {
          if (info.dropToGap) {
            arr.splice(index, 1)
            dragObj = item
          }
        })
        
    if (info.dropToGap) {
      let ar
      let i
      loop(data, dropKey, (item, index, arr) => {
        ar = arr
        i = index
      })

      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj)
      } else {
        if((!dragObj?.parent && !ar.every(folder=>folder?.parent)) || (dragObj?.parent && ar.every(folder=>folder?.parent === dragObj?.parent))){
          ar.splice(i + 1, 0, dragObj)
        }
      }

      data = data.map((data, index)=>({...data, order:index+1, children: data?.children ? data.children.map(folder=>({...folder, order:index+1})) : [] }))

      // checking dragged folder validity
      data.forEach((folder, index, array)=>{
        folder.children.forEach((child, index, array)=>{
          if (child.id === dragKey){
          if (!child?.parent && child.children.length !== 0){
            isValid = false
            this.$showToast({message:'Can not move a category to another category.', type:'success'})
            // this.showMessageModal = true
            // this.message = {
            //   title: "Can not move a category to another category.",
            //   type: "error",
            // };
          }
          else{
            isValid = true
          }
        }
        })
      })

      if (isValid){
        this.categoryToBeUpdateOrder = dragKey
        this.archiveFolders = [...data]
      }
    }
    },
  }
};
</script>

<style lang="scss">
.archive-management-sidebar {
  height: 100%;
  min-height: calc(100vh - 25rem);
  position: relative;
  margin-bottom: -1px;
  border-right: 1px solid $color-dark-grey-5;
  margin-right: 1.3rem;
  @include respond(phone-x-small) {
    border-right: 0;
    min-height: 100%;
  }
  &__header {
    padding: 0.5rem 2.6rem 0.4rem;
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    &--name {
      display: flex;
      align-items: center;
      width: 100%;
      h3 {
        font-size: 2rem;
        font-family: $font-primary-bold;
        margin-bottom: 0;
        padding: 0;
        line-height: 2.3rem;
        @include respond(tab-port) {
          font-size: 2.2rem;
          line-height: 2.8rem;
        }
      }
      .close-btn {
        display: none;
        margin-left: auto;
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        line-height: normal;
        border: none;
        outline: none;
        cursor: pointer;
        .icon {
          transform: rotate(45deg);
          display: block;
          font-size: 2.5rem;
        }
        @include respond(tab-port) {
          display: block;
        }
      }
    }
  }
  &__body {
    .list {
      margin: 0 2.1rem 4rem 2.6rem;
      position: relative;
      height: 100%;
      // overflow-y: auto;
      overflow: hidden;
      // max-height: 44rem;
      // &::-webkit-scrollbar {
      //   height: 0.3rem;
      //   width: 0.3rem;
      // }

      // &::-webkit-scrollbar-track {
      //   background-color: transparent;
      //   border-radius: 0.8rem;
      //   height: 0.3rem;
      //   width: 0.3rem;
      // }

      // &::-webkit-scrollbar-thumb {
      //   background-color: $color-primary;
      //   border-radius: 0.8rem;
      // }
      .ant-tree > li:last-child {
        margin-bottom: 0 !important;
      }

      .ant-tree {
        height: 100%;
        overflow: hidden;
        li {
          margin-bottom: 1.2rem !important;
        }
        .ant-tree-treenode-switcher-open,
        .ant-tree-treenode-switcher-close {
          padding: 1.2rem 1.4rem 0.2rem;
          background-color: #F5F5F5;
          border-radius: 0.8rem;
          margin-bottom: 0;
          .ant-tree-switcher {
            width: 2rem;
            height: 2rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            .anticon {
              font-size: 1.6rem !important;
            }
          }

          .ant-tree-node-content-wrapper {
            padding: 0;
            margin-bottom: 1rem;
            padding: 0 1rem;
            display: inline-flex;
            align-items: center;
            position: relative;
            border-radius: 0.5rem;
            height: 2.4rem;
            &:hover {
              background-color: rgb(217, 217, 217);
            }

            .ant-tree-icon__customize {
              width: 1.8rem;
              height: 1.8rem;
              margin: 0 1.2rem 0 0.8rem;
              position: relative;
              z-index: 1;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .ant-tree-title {
              width: calc(100% - 5rem);
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: auto;
              .title {
                font-size: 1.4rem;
                font-family: $font-primary-medium;
                line-height: normal;
                color: $color-black;
                width: 100%;
                overflow: hidden;
                
                span {
                  color: $color-black;
                  position: relative;
                  z-index: 1;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;
                  width: 100%;
                }
              }
              .active {
                &::before {
                  position: absolute;
                  background-color: rgb(217, 217, 217);
                  content: '';
                  left: 0;
                  top: -1px;
                  height: calc(100% + 1px);
                  width: 100%;
                  border-radius: 0.5rem;
                }
              }
            }
          }

          .ant-tree-node-selected {
            background-color: transparent;
          }
          .ant-tree-child-tree {
            li {
              padding: 0;
              margin-bottom: 0 !important;
            }
          }
        }
      }
      
      
      .folder-skeleton {
        margin-bottom: 1.1rem;
        .ant-skeleton-content {
          .ant-skeleton-paragraph {
            margin: 0;
            li {
              height: 5rem;
            }
          }
        }
      }
      .message {
        padding-top: 4rem;
        p {
          font-size: 1.6rem;
          font-family: $font-primary;
          margin-bottom: 0;
          line-height: 2.3rem;
          color: $color-black;
          text-align: center;
        }
      }
    }
  }
}
</style>
