<template>
  <div class="archive-management">
    <a-row :gutter="[0, 0]">
      <a-col :xs="24" :sm="10" :md="10" :lg="6" :xl="6">
        <archive-sidebar @select-folder="updateTitle"></archive-sidebar>
      </a-col>
      <a-col :xs="24" :sm="14" :md="14" :lg="18" :xl="18">
        <archive-content></archive-content>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ArchiveSidebar from "./ArchiveSidebar.vue";
import ArchiveContent from "./ArchiveContent.vue";
import {mapActions} from "vuex"

export default {
  data() {
    return {};
  },
  components: {
    ArchiveSidebar,
    ArchiveContent,
  },
  methods:{
    ...mapActions({
      emptyFolderTreeAndContent: 'folders/emptyFolderTreeAndContent'
    }),
  },
  beforeUnmount(){
    this.emptyFolderTreeAndContent()
  }
};
</script>
