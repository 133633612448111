<template>
  <a-modal
    width="58rem"
    :visible="visible"
    @cancel="cancelpublicationModal"
    class="publication-modal-main"
  >
    <div class="publication-modal">
      <h1 class="publication-modal__heading">{{ isEdit ? 'Edit Publication' : 'Add Publication'  }}</h1>
      <div class="publication-modal__form-fields">
        <a-form
          class="modal-form"
          ref="formRef"
          :label-col="{ style: { width: '21rem' } }"
          :model="publication"
          :rules="rules"
          @finish="submitPublication"
        >
          <a-form-item label-align="left" label="Title" name="title">
            <a-input v-model:value="publication.title" placeholder="Ex: Giving and receiving feedback" />
          </a-form-item>

          <a-form-item label-align="left" label="Publication Date" name="publication_date"
           :rules="[{ validator: (rule, value) => validateDates(rule, value), trigger: 'change' }]"
          >
            <a-date-picker
              v-model:value="publication.publication_date"
              picker="month"
              placeholder="Publication Date"
              style="width: 100%"
              format="MMM YYYY" 
              
          />
        </a-form-item>

        <a-form-item label-align="left" label="Expected Publication Date" name="expected_publication_date"
         :rules="[{ validator: (rule, value) => validateDates(rule, value), trigger: 'change' }]"
        >
          <a-date-picker
            v-model:value="publication.expected_publication_date"
            picker="month"
            placeholder="Expected Publication Date"
            style="width: 100%"
            format="MMM YYYY" 
            :disabled-date="disabledDate"
        />
      </a-form-item>

          <a-form-item label-align="left" label="Link" name="url">
            <a-input v-model:value="publication.url" placeholder="Ex: https://www.researchgate.net/publication... " />
          </a-form-item>

          <a-form-item label="Cover Image" label-align="left" name="image">
            <a-upload v-model:file-list="publication.image"
                list-type="picture-card"
                :maxCount="1"
                :beforeUpload="()=>{return false}"
                accept='image/png, image/jpeg, image/webp, image/jpg'
                class="publication-image-upload-container"
              >
              <div class="upload-btn" v-if="publication?.image.length === 0">
                <div class="upload-btn__icon">
                  <img src="@/assets/icons/add_photo.svg" />
                </div>
                <h4 class="upload-btn__title">Add Cover Image</h4>
              </div>
              <a-spin v-if="loading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                <div>Uploading...</div>
              </a-spin>
            </a-upload>
          </a-form-item>

          <a-form-item label="Upload Publication" label-align="left" name="upload_publication">
            <a-upload v-model:file-list="publication.upload_publication"
                list-type="picture-card"
                :maxCount="1"
                :show-preview-icon="false" 
                :beforeUpload="()=>{return false}"
                @change="handleChange"
                accept=".pdf"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                class="publication-image-upload-container"
              >
              <div class="upload-btn" v-if="publication.upload_publication.length === 0">
                <div class="upload-btn__icon">
                  <img src="@/assets/icons/add_photo.svg" />
                </div>
                <h4 class="upload-btn__title">Upload Publication</h4>
              </div>
            </a-upload>
          </a-form-item>


          <a-form-item>
            <a-button :disabled="buttonLoader || disable" :loading="buttonLoader" class="form-btn" :class="{'disable': disable}" html-type="submit" >
              {{ isEdit ? 'Update' : 'Add'  }}
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {mapActions} from 'vuex'
import pdfIconPath from '@/assets/icons/pdf-icon.png';
import moment from 'moment'
export default {
  props: {
    visible: Boolean,
    publicationDetails:{
      default: ()=>({}),
      type: Object
    },
    isEdit:{
      default: () => (false),
      type: Boolean
    },
    buttonLoader:{
      default: () =>(false),
      Boolean
    },
  },
  emits: ["close-modal", "submit-publication"],
  data: ()=>({
    publication:{},
    fileList:[],
    rules:{
      title:[
        {
          required: true,
          message: 'Please enter title',
          trigger: 'blur'
        }
      ],
      url:[
        { 
          pattern: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2})?([/?].*)?$/,
          message: 'Please enter a valid link',
          trigger: 'change'
        }
      ],

    },
    publicationFileList:[],
    loading: false,
    disable:false
  }),
  watch:{
    visible:{
     async handler(){
      this.disable = true
      this.publication = {...this.publicationDetails}
      this.publication.image = this.publicationDetails.image ? [{id:1, status:'uploading'}] : []
      this.publication.upload_publication =  this.publicationDetails.upload_publication ? [{id:1, status:'uploading'}] : []

      const duplicatePublication = {...this.publicationDetails}
      const imageObject = await this.urlToObject(duplicatePublication?.image);
      duplicatePublication.image = duplicatePublication?.image ? [imageObject] : [];
      duplicatePublication.upload_publication = duplicatePublication?.upload_publication
        ? [
            await this.urlToObject(
              duplicatePublication?.upload_publication,
              duplicatePublication?.title
            ),
          ]
        : [];
      this.publication = { ...duplicatePublication };
      console.log("DISABLE", this.disable)
      this.disable = false

      }, immediate: true
    }
  },
  methods: {
    ...mapActions({
      addPublications: 'profile/addPublications',
      editPublications: 'profile/editPublications'
    }),
    cancelpublicationModal() {
      this.$refs.formRef.clearValidate()
      this.$refs.formRef.resetFields()
      Object.keys(this.publication).forEach(key=>{
        this.publication[key] = key === 'image' ||  key === 'upload_publication' ? [] : ''
      }) 
      this.$emit("close-modal");
     
    },
    async submitPublication(){

      this.$refs.formRef
        .validate()
        .then(async () => {
          
          const formData = new FormData()
      
          Object.entries(this.publication).forEach(([key, value])=>{
            if(key ===  'image'){
              value.length > 0 ? formData.append(key, value[0].originFileObj) : formData.append(key, '') 
            } 
            else if(key === 'upload_publication'){
              value.length > 0 ? formData.append(key, value[0].originFileObj) : formData.append(key, '') 
            }
            else if (key === 'publication_date' || key === 'expected_publication_date'){
              formData.append(key, value ? this.setDateFormat(value) : '')
            }
            else if (key !==  'image' && key !== 'upload_publication'){
              formData.append(key, value)
            }
          })

          this.$emit("submit-publication",  formData)
          
        }).catch((err)=>{
          console.log(err)
        })
    },
    setDateFormat(date) {
      date =
        typeof date == "object" && date !== null
          ? date._d.getFullYear() +
            "-" +
            (date._d.getMonth() + 1) +
            "-" +
            date._d.getDate()
          : date;
      return date;
    },
    async urlToObject(url, title = "publication") {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = blob.type.includes("pdf")
        ? `${title.slice(0,96)}.pdf`
        : `image.${blob.type.split("/")[1]}`;
      const file = new File([blob], fileName, { type: blob.type });
      // const pdfIcon = new File([blob], `image.${blob.type}`, { type: blob.type });
      const data = {
        uid: "vc-upload-" + Date.now(),
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        size: file.size,
        type: file.type,
        originFileObj: file,
        thumbUrl: blob.type.includes("pdf") ? pdfIconPath : URL.createObjectURL(blob),
      };
      return data;
    },
    handleChange(){
      if (this.publication?.upload_publication.length > 0)
      this.publication.upload_publication[0].thumbUrl = pdfIconPath
    },
    validateDates(rule, value) {
      const formValues = this.$refs.formRef.getFieldsValue(); // Get all form values
      const { publication_date, expected_publication_date } = formValues;

      // Check if at least one date is selected
      if (!publication_date && !expected_publication_date) {
        return Promise.reject('Please select at least one of the publication dates.');
      }

      if (publication_date && expected_publication_date){
        return Promise.reject('Please select any one of the publication dates.');
      }

      return Promise.resolve(); // Validation passed
    },
    disabledDate(current) {
      return current && current < moment().startOf('day');
    }
  },
};
</script>
<style lang="scss">
.publication-modal-main {
  top: 8rem;
  @include respond(tab-port) {
    top: 5rem;
  }
  .ant-modal-content {
    position: relative;
    .ant-modal-close {
      margin: 2.8rem 2.4rem 0 0;
    }
    .ant-modal-body {
      .publication-modal {
        padding: 3rem;
        background-color: #f6f8ff;
        border-radius: 1rem;
        &__heading {
          font-size: 2.2rem;
          font-family: $font-primary-bold;
          margin-bottom: 3rem;
          text-align: center;
          line-height: normal;
        }
        &__form-fields {
          .modal-form {
            .ant-form-item {
              margin-bottom: 2rem;
              @include respond(phone-x-small) {
                margin-bottom: 0;
              }
              .ant-form-item-label {
                padding-bottom: 0;
                label {
                  font-size: 1.6rem;
                  font-family: $font-primary;
                  height: auto;
                }
              }
              .ant-form-item-control {
                .ant-form-item-control-input {
                  .ant-form-item-control-input-content {
                    .ant-input {
                      padding: 0.4rem 1rem;
                      font-size: 1.6rem;
                      font-family: $font-primary;
                      margin-bottom: 0.5rem;
                    }
                    .error {
                      width: auto;
                      font-size: 1.4rem;
                      font-family: $font-primary;
                      color: red;
                      line-height: normal;
                    }
                    .publication-image-upload-container {
                      .ant-upload {
                        width: 100%;
                        background-color: $color-white;
                        margin: 0;
                        height: 8rem;
                        .upload-btn {
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          background-color: $color-white;
                          &__icon {
                            background-color: rgb(228, 230, 235);
                            height: 3rem;
                            width: 3rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                            margin-bottom: 1rem;
                            img {
                              width: 1.5rem;
                              opacity: 0.4;
                            }
                          }
                          &__title {
                            font-size: 1.2rem;
                            font-family: $font-primary;
                            line-height: normal;
                            margin-bottom: 0.5rem;
                            color: $color-black;
                            opacity: 0.4;
                          }
                        }
                      }
                      .ant-upload-list {
                        .ant-upload-list-picture-card-container {
                          height: 8rem;
                          width: 8rem;
                          float: none;
                          margin: 0;
                          .ant-upload-list-item {
                            border-color: $color-dark-grey-5 !important;
                            margin: 0;
                            padding: 0.5rem;
                            height: 8rem;
                            width: 8rem;
                            .ant-upload-list-item-actions {
                              a {
                                display: none !important;
                              }
                            }
                            .ant-upload-list-item-info {
                              .ant-upload-list-item-uploading-text {
                                font-size: 1.2rem;
                                font-family: $font-primary;
                                line-height: normal;
                                margin-bottom: 0;
                                color: $color-black;
                                margin-top: 4rem;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .ant-form-item-explain {
                  font-size: 1.4rem;
                  font-family: $font-primary;
                  color: red;
                  margin-bottom: 0;
                  line-height: normal;
                }
              }
              .ant-picker-month .ant-picker-month-panel .ant-picker-cell-inner {
                display: none;
              }
              
            }
          }
          .ant-btn {
            border: none;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            cursor: pointer;
            background-color: $color-primary;
            color: $color-white;
            transition: all 0.2s;
            height: 4rem;
            width: 10rem;
            margin-top: 1rem;
            margin-left: auto;
            margin-bottom: -2rem;
            display: block;
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
              border: none;
            }
            span {
              line-height: normal;
            }
          }
          .ant-btn.disable {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>
