<template>
  <div class="publication-section">
    <div class="publication-section__header">
      <h2 class="publication-section__header--title">Publications</h2>
      <button
        v-if="isLoggedInUser && !isSharedProfileView"
        class="publication-section__header--btn"
        id="addpublication"
        title="addpublication"
        @click="addpublication()"
      >
        <i class="icon icon-add_plus"></i>
      </button>
    </div>
    <div class="publication-section__body">
      <template v-if="fetchPublicationLoader">
        <div v-for="loaders in 3" :key="loaders">
          <ExperienceSkeletonLoader />
        </div>
      </template>
      <template v-else>
        <template v-if="publications.length === 0">
          <p class="message">No publications to show</p>
        </template>
        <template v-else>
          <div
            class="publication-section__body--publication"
            v-for="publication in publications"
            :key="publication.id"
          >
            <div class="header">
              <div class="header__details">
                <img v-if="publication?.image" :src="publication.image" />
                <div class="header__details--title-date">
                  <h2>
                    {{ publication?.title }}
                  </h2>
                  <p><span>{{ publication.is_expected ? 'Expected Publication Date:' : 'Publication Date:'}}</span> {{ formattedDate(publication?.publication_date || publication?.expected_publication_date) }} </p>
                </div>
              </div>
              <div class="header__edit-delete-btn" v-if="isLoggedInUser && !isSharedProfileView">
                <button
                  class="btn delete-btn"
                  id="publicationDeleteBtn"
                  title="publicationDeleteBtn"
                  @click.stop="toggleDeleteModal(publication)"
                >
                  <i class="icon icon-dustbin"></i>
                </button>
                <button
                  class="btn edit-btn"
                  id="publicationEditBtn"
                  title="publicationEditBtn"
                  @click="openEditPublicatonModal(publication)"
                >
                  <img
                    src="@/assets/icons/edit-icon.svg"
                    width="auto"
                    height="auto"
                    alt="Edit"
                  />
                </button>
              </div>
            </div>
            <div class="body">
              <a v-if="publication?.url" @click.stop="openPublication(publication.url)"
                >Show publication
                <img src="@/assets/icons/new-tab-icon.png" alt="Icon" />
              </a>
              <a
                @click.stop="openPublication(publication.upload_publication, publication?.title, true)"
                v-if="publication.upload_publication"
                >Download publication
                <img src="@/assets/icons/download-icon-bold.png" alt="Icon" class="download-icon" />
              </a>
            </div>
          </div>
        </template>
      </template>
      <!-- </template> -->
      <!-- <p class="message" v-else>
        No record added
      </p> -->
    </div>
  </div>
  <delete-modal
    :visible="deleteModalVisible"
    @handle-close="deleteModalVisible = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :message="null"
    @delete-record="deletePublication"
    :loading="deleteLoading"
  ></delete-modal>
  <add-publications-modal
    ref="publications-modal"
    :visible="showAddpublicationsModal"
    @close-modal="closePublicationsAddEditModal()"
    :footer="null"
    :publicationDetails="publicationDetails"
    :isEdit="isEditPublication"
    :buttonLoader="addUpdateButtonLoader"
    @submit-publication="submitPublication"
  ></add-publications-modal>
</template>

<script>
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import AddPublicationsModal from "./AddPublicationsModal.vue";
import ExperienceSkeletonLoader from "../ProfilePageComponentsV2/SkeletonLoaders/ExperienceSkeletonLoader.vue"
import { mapGetters, mapActions } from "vuex";
import moment from 'moment'

export default {
  data() {
    return {
      showAddpublicationsModal: false,
      publicationDetails: {},
      isEditPublication: false,
      deleteModalVisible: false,
      deleteLoading: false,
      fetchPublicationLoader: false,
      addUpdateButtonLoader: false,
      publication: {
        title: "",
        url: "",
        image: "",
        upload_publication: "",
        publication_date: "",
        expected_publication_date: ""
      },
      showMessageModal:false,
      message: {},
    };
  },
  components: {
    DeleteModal,
    AddPublicationsModal,
    ExperienceSkeletonLoader
  },
  computed: {
    ...mapGetters({
      publications: "profile/publications",
      isSharedProfileView: 'profile/isSharedProfileView'
    }),
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
  },
  async created() {
    await this.fetchingPublications();
  },
  methods: {
    ...mapActions({
      fetchPublications: "profile/fetchPublications",
      deletePublications: "profile/deletePublications",
      addPublications: "profile/addPublications",
      editPublications: "profile/editPublications",
    }),
    addpublication() {
      this.showAddpublicationsModal = true;
    },
    async openEditPublicatonModal(publication) {
      this.publication = { ...publication };
      this.publicationDetails = {...publication}

      this.showAddpublicationsModal = true;
      this.isEditPublication = true;
    },
    closePublicationsAddEditModal() {
      this.showAddpublicationsModal = false;
      this.isEditPublication = false;
      Object.keys(this.publication).forEach((key) => {
        this.publication[key] = "";
      });
      Object.keys(this.publicationDetails).forEach((key) => {
        this.publicationDetails[key] = "";
      });
    },
    toggleDeleteModal(publication = null) {
      this.publicationDetails = publication;
      this.deleteModalVisible = !this.deleteModalVisible;
      this.deleteLoading = false;
    },
    async deletePublication() {
      try {
        this.deleteLoading = true;
        await this.deletePublications(this.publicationDetails?.id);
        await this.fetchPublications();
        this.$showToast({message:'Publication record removed successfully.', type:'success'})
      } catch (err) {
        this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
        console.log(err);
      } finally {
        this.toggleDeleteModal();
      }
    },
    async fetchingPublications() {
      this.fetchPublicationLoader = !this.fetchPublicationLoader;
      await this.fetchPublications();
      this.fetchPublicationLoader = !this.fetchPublicationLoader;
    },
    async submitPublication(publication) {
      try {
        const message = this.isEditPublication ? "Publication record updated successfully." : "Publication record added successfully."
        this.addUpdateButtonLoader = !this.addUpdateButtonLoader;
        // console.log(this.publication, "PUBLICATION");
        this.isEditPublication
          ? await this.editPublications({
              publication,
              publicationId: this.publication.id,
            })
          : await this.addPublications(publication);
        await this.fetchingPublications();
        this.closePublicationsAddEditModal();
        this.addUpdateButtonLoader = !this.addUpdateButtonLoader;
        // this.showMessageModal = true;
        this.$showToast({message, type:'success'})

      } catch (err) {
        this.addUpdateButtonLoader = !this.addUpdateButtonLoader;
        this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
        // this.showMessageModal = true;
        // this.message = {
        //   title: this.isEditPublication ? "Error occurred while updating publication" : "Error occurred while uploading publication.",
        //   type: "error",
        // };
        // console.log(err);
      }
    },
    async openPublication(url, name='', isPdf=false) {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank"
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    formattedDate(date) {
        return moment(date).format('MMM YYYY');
    },
  },
};
</script>

<style lang="scss">
.publication-section {
  padding: 3rem 2.6rem;
  border-top: 1px solid $color-dark-grey-5;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.7rem;
    &--title {
      font-size: 2rem;
      font-family: $font-primary-medium;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      @include respond(tab-port) {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }
    &--btn {
      background-color: $color-primary;
      height: 4.2rem;
      width: 4.2rem;
      border: none;
      outline: none;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon {
        color: $color-white;
        font-size: 1.6rem;
      }
    }
  }
  &__body {
    &--publication {
      padding: 2.4rem 0 0;
      .header {
        display: flex;
        align-items: flex-start;
        &__details {
          width: 100%;
          padding-right: 2rem;
          display: flex;
          align-items: center;
          gap: 1rem;
          img {
            width: 8rem;
            height: 8rem;
            object-fit: cover;
            border-radius: 0.5rem;
            border: 1px solid $color-dark-grey-5;
          }
          &--title-date {
            h2 {
              font-size: 1.8rem;
              font-family: $font-primary-medium;
              line-height: 2.2rem;
              color: $color-black;
              margin-bottom: 0;
            }
            p {
              font-size: 1.4rem;
              font-family: $font-primary;
              margin-bottom: 0;
              line-height: 1.6rem;
              color: #6f6f6f;
              margin-top: 0.5rem;
              span {
                font-family: $font-primary-medium;
              }
            }
          }
        }
        &__edit-delete-btn {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          .btn {
            line-height: normal;
            width: fit-content;
            height: fit-content;
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            img {
              width: 2.5rem;
              height: auto;
            }
            .icon {
              color: $color-black;
              font-size: 1.8rem;
              display: block;
            }
            @include respond(tab-port) {
              img {
                width: 3rem;
              }
              .icon {
                font-size: 2.3rem;
              }
            }
          }
        }
      }
      .body {
        margin: 1.5rem 0 1rem;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        a {
          font-size: 1.4rem;
          font-family: $font-primary-bold;
          margin-bottom: 0;
          line-height: 1.6rem;
          color: $color-black;
          cursor: pointer;
          border: 1px solid $color-black;
          padding: 0.8rem 1.5rem;
          display: flex;
          align-items: center;
          width: fit-content;
          opacity: 0.7;
          border-radius: 100px;
          cursor: pointer;
          img {
            margin-left: 1rem;
            width: 1.2rem;
            height: auto;
          }
          .download-icon {
            width: 1.5rem;
          }
        }
      }
    }
    .message {
      font-size: 1.6rem;
      font-family: $font-primary;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      margin-bottom: 3rem;
    }
  }
}
</style>
