<template>
  <a-modal width="49.57rem" class="add-subject-modal-main" @cancel="closeModal">
    <div class="add-subject-modal">
      <div class="add-subject-modal__header" v-if="getUserInfo.account_type === 'basic'">
        <h1 v-if="action == 'edit'" class="add-subject-modal__header--heading">
        Edit Subject Matter Expertise
        </h1>
        <h1 v-else class="add-subject-modal__header--heading">
          Add Subject Matter Expertise
        </h1>
      </div>
      <div class="add-subject-modal__header" v-if="getUserInfo.account_type === 'organization'">
        <h1 v-if="action == 'edit'" class="add-subject-modal__header--heading">
          Edit Focus Areas
        </h1>
        <h1 v-else class="add-subject-modal__header--heading">
          Add Focus Areas
        </h1>
      </div>
      <div class="add-subject-modal__add-category">
        <a-input data-cy="SMETopic" v-model:value="topicTitle" placeholder="Enter here…" />
        <p v-if="error" class="error">{{ error }}</p>
      </div>
      <a-button
      data-cy="updateNewSMEButton"
        v-if="action == 'edit'"
        :loading="loading"
        class="save-btn btn-primary load-btn"
        @click="updateTopic"
        >Update</a-button
      >
      <a-button
        data-cy="addNewSMEButton"
        v-else
        :loading="loading"
        class="save-btn btn-primary load-btn"
        @click="addTopic"
        >Add</a-button
      >
    </div>
  </a-modal>
</template>
<script>
export default {
  props: ["action"],
  emits: ["close-modal"],

  data() {
    return {
      topicTitle: "",
      topic: {},
      showMessageModal: false,
      error: "",
      message: {},
      loading: false,
      isOrg: ""
    };
  },
  computed:{
    getUserInfo(){
      return this.$store.getters["profile/getSelectedUserProfile"];
    },
  },
  methods: {
    selectTopic(topic) {
      this.topic = topic;
      this.topicTitle = topic.title;
    },
    async addTopic() {
      if (this.topicTitle == "") {
        this.error = "This field is required";
        return;
      }
      this.loading = true;
      try {
        await this.$store
          .dispatch("profile/addTopic", this.topicTitle)
          .then(() => {
            this.closeModal();
            this.$showToast({message:'Subject Matter Expertise added successfully', type:'success'})
            // this.message = {
            //   title: "Subject Matter Expertise Added Successfully",
            //   // desc : 'Your topic has been added successfully. Thanks for using Glocal.',
            //   type: "success",
            // };
            // this.showMessageModal = true;
          });
      } catch (err) {
        this.closeModal();
        this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
        // this.message = {
        //   title: "Something went wrong",
        //   desc: "Please Try Again later. Sorry for the inconvenience",
        //   type: "failure",
        // };
        // this.showMessageModal = true;
      }
      this.loading = false;
    },
    async updateTopic() {
      if (this.topicTitle == "") {
        this.error = "This field is required";
        return;
      }
      let requestObj = {
        id: this.topic.id,
        active: this.topic.active,
        title: this.topicTitle,
      };
      this.loading = true;
      try {
        await this.$store
          .dispatch("profile/updateTopic", requestObj)
          .then(() => {
            this.closeModal();
            this.$showToast({message:'Subject Matter Expertise updated successfully', type:'success'})
            // this.message = {
            //   title: "Updated Successfully",
            //   // desc : 'Your topic has been updated successfully. Thanks for using Glocal.',
            //   type: "success",
            // };
            // this.showMessageModal = true;
          });
      } catch (err) {
        this.closeModal();
        this.$showToast({message:'Something went wrong. Please try again later', type:'failure'})
        // this.message = {
        //   title: "Something went wrong",
        //   desc: "Please Try Again later. Sorry for the inconvenience",
        //   type: "failure",
        // };
        // this.showMessageModal = true;
      }
      this.loading = false;
    },
    closeModal() {
      this.topic = {};
      this.topicTitle = "";
      this.loading = false;
      this.error = "";
      this.$emit("close-modal");
    },
  },
};
</script>
<style lang="scss">
.add-subject-modal-main {
  .ant-modal-content {
    position: relative;
    .ant-modal-close {
      margin: 2.7rem 2.2rem 0 0;
    }
    .ant-modal-body {
      .add-subject-modal {
        padding: 3rem;
        background-color: #f6f8ff;
        border-radius: 1rem;
        &__header {
          &--heading {
            font-size: 2.2rem;
            font-family: $font-primary-bold;
            margin-bottom: 3rem;
            text-align: left;
            line-height: normal;
          }
        }
        &__add-category {
          .ant-input {
            padding: 0.8rem 1rem;
            font-size: 1.6rem;
            font-family: $font-primary;
            @include respond(tab-port) {
              font-size: 1.8rem;
            }
          }
        }
        .ant-btn {
          border: none;
          font-size: 1.6rem;
          font-family: $font-primary-medium;
          cursor: pointer;
          background-color: $color-primary;
          color: $color-white;
          transition: all 0.2s;
          height: 4rem;
          width: 10rem;
          margin-top: 2rem;
          margin-left: auto;
          display: block;
          &:hover,
          &:active,
          &:focus {
            background-color: $color-primary;
            color: $color-white;
            border: none;
          }
          span {
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>
