<template>
  <a-modal
    width="53rem"
    :visible="visible"
    @cancel="closeOrganizationLocationModal"
    class="organization-location-modal-main"
  >
    <div class="organization-location">
      <h1 class="organization-location__heading">
        {{ isEdit ? "Edit Location" : "Add Location" }}
      </h1>
      <div class="organization-location__form-fields">
        <a-form
          class="modal-form"
          ref="formRef"
          :label-col="{ style: { width: '13rem' } }"
          :model="location"
          :rules="rules"
          @finish="addLocation"
        >
          <a-form-item label-align="left" label="Name" name="name">
            <a-input
              v-model:value="location.name"
              placeholder="Please enter location name"
            />
          </a-form-item>

          <a-form-item label-align="left" label="Location" name="address">
            <a-auto-complete
              v-model:value="location.address"
              placeholder="Search location"
              :default-active-first-option="false"
              :filter-option="false"
              :options="searchedLocationResults"
              @select="handleLocation"
              @search="handleSearch"
              :not-found-content="undefined"
            >
              <template v-if="fetching" #notFoundContent>
                <a-skeleton
                  active
                  class="dropdown-search-skeleton"
                  :title="null"
                  :paragraph="{ rows: 3, width: ['full'] }"
                />
              </template>
            </a-auto-complete>
          </a-form-item>

          <a-form-item label-align="left" label="Contact" name="contact">
            <a-input
              v-model:value="location.contact"
              placeholder="Please enter a contact number"
            />
          </a-form-item>

          <a-form-item>
            <a-button
              :disabled="buttonLoader || disable"
              :loading="buttonLoader"
              class="form-btn"
              :class="{ disable: disable }"
              html-type="submit"
            >
              {{ isEdit ? "Update" : "Add" }}
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    visible: Boolean,
    locationDetails: {
      default: () => ({}),
      type: Object,
    },
    isEdit: {
      default: () => false,
      type: Boolean,
    },
    buttonLoader: {
      default: () => false,
      Boolean,
    },
  },
  emits: ["close-modal", "add-organization-location"],
  data: () => ({
    location: {},
    rules: {
      name: [
        {
          required: true,
          message: "Please enter location name",
          trigger: "blur",
        },
      ],
      address: [
        {
          required: true,
          message: "Please enter a valid location",
          trigger: "blur",
        },
      ],
      contact: [
        {
          pattern: /^\+(\d{1,3})\d{4,12}$/,
          message: "Please enter your phone number including the country code (e.g., +12345555555).",
          trigger: "blur",
        },
      ],
    },
    loading: false,
    disable: false,
    fetching: false,
    locationOptions: [],
  }),
  computed: {
    ...mapGetters({
      searchedLocationResults: "profile/searchedLocationResults",
    }),
  },
  watch: {
    visible: {
      async handler() {
        this.location = JSON.parse(JSON.stringify(this.locationDetails));
      },
      immediate: true,
    },
    searchedLocationResults: {
      handler(val) {
        this.locationOptions = val;
      },
      immediate: true,
    },
    buttonLoader:{
      handler(val){
        if (!val){
          this.resetFormValues()
        }
      }
    }
  },
  methods: {
    ...mapActions({
      searchLocation: "profile/searchLocation",
      emptySearchedLocations: "profile/emptySearchedLocations",
    }),
    async handleSearch(e) {
      if (e) {
        this.fetching = true;

        this.debounce(async () => {
          const that = this;
          try{
            await this.searchLocation(e);
            that.fetching = false;
          }
          catch(err){
            that.fetching = false;
          }
        }, 500);
      }
      else{
        this.emptySearchedLocations();
      }
    },
    async handleLocation(e) {
      this.location.address = e;
      this.emptySearchedLocations();
    },
    closeOrganizationLocationModal() {
      this.resetFormValues()
      this.$emit("close-modal");
    },
    async addLocation() {
      const location = JSON.parse(JSON.stringify(this.location));
      this.$refs.formRef
        .validate()
        .then(async () => {
          this.$emit("add-organization-location", location);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    resetFormValues(){
      this.$refs.formRef.clearValidate();
      this.$refs.formRef.resetFields();
      this.emptySearchedLocations();
    }
  },
};
</script>
<style lang="scss">
.organization-location-modal-main {
  top: 8rem;
  @include respond(tab-port) {
    top: 5rem;
  }
  .ant-modal-content {
    position: relative;
    .ant-modal-close {
      margin: 2.8rem 2.4rem 0 0;
    }
    .ant-modal-body {
      .organization-location {
        padding: 3rem;
        background-color: #f6f8ff;
        border-radius: 1rem;
        &__heading {
          font-size: 2.2rem;
          font-family: $font-primary-bold;
          margin-bottom: 3rem;
          text-align: center;
          line-height: normal;
        }
        &__form-fields {
          .modal-form {
            .ant-form-item {
              margin-bottom: 2rem;
              @include respond(phone-x-small) {
                margin-bottom: 0;
              }
              .ant-form-item-label {
                padding-bottom: 0;
                label {
                  font-size: 1.6rem;
                  font-family: $font-primary;
                  height: auto;
                }
              }
              .ant-form-item-control {
                .ant-form-item-control-input {
                  .ant-form-item-control-input-content {
                    .ant-input {
                      padding: 0.4rem 1rem;
                      font-size: 1.6rem;
                      font-family: $font-primary;
                      margin-bottom: 0.5rem;
                      color: $color-black;
                    }
                    .error {
                      width: auto;
                      font-size: 1.4rem;
                      font-family: $font-primary;
                      color: red;
                      line-height: normal;
                    }
                    .ant-select {
                      margin-bottom: 0.5rem;
                      .ant-select-selector {
                        border: 1px solid #d9d9d9;
                        background-color: $color-white;
                        outline: none;
                        height: 3.5rem !important;
                        box-shadow: none;
                        margin-bottom: 0;
                        border-radius: 0.5rem !important;
                        padding: 0;
                        font-size: 1.6rem;
                        font-family: $font-primary;
                        color: $color-black;
                        .ant-select-selection-search {
                          height: 100%;
                          .ant-select-selection-search-input {
                            height: 100%;
                            padding: 0 1rem;
                            margin: 0;
                            border: none;
                          }
                        }
                        .ant-select-selection-placeholder {
                          opacity: 0.3;
                          color: $color-black;
                        }
                        .ant-select-selection-item {
                          font-size: 1.4rem;
                          font-family: $font-primary;
                          color: $color-black;
                          max-width: 30rem;
                        }
                      }
                      .ant-select-arrow {
                        top: 50%;
                        transform: translateY(0%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        right: 1.2rem;
                        .anticon {
                          font-size: 1.3rem;
                          color: $color-black;
                          opacity: 0.5;
                        }
                      }
                    }
                  }
                }
                .ant-form-item-explain {
                  font-size: 1.4rem;
                  font-family: $font-primary;
                  color: red;
                  margin-bottom: 0;
                  line-height: normal;
                }
              }
              .ant-picker-month .ant-picker-month-panel .ant-picker-cell-inner {
                display: none;
              }
            }
            .ant-form-item-has-error {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  .ant-select {
                    .ant-select-selector {
                      border-color: red !important;
                    }
                  }
                }
              }
            }
          }
          .ant-btn {
            border: none;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            cursor: pointer;
            background-color: $color-primary;
            color: $color-white;
            transition: all 0.2s;
            height: 4rem;
            width: 10rem;
            margin-top: 1rem;
            margin-left: auto;
            margin-bottom: -2rem;
            display: block;
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
              border: none;
            }
            span {
              line-height: normal;
            }
          }
          .ant-btn.disable {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>
