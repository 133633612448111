<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    class="create-event-modal"
    :title="!eventId?'Create an event':'Edit an event'"
  >
    <div class="create-event-modal-container">
      <div class="create-event-modal-container__body">
        <a-upload
          v-model:file-list="fileListMedia"
          list-type="picture-card"
          @change="getImage"
          accept=".jpg, .jpeg, .png, .webp"
          :before-upload="beforeUploadMedia"
          :remove="deletefileList"
        >
          <div class="upload-btn" v-if="fileListMedia.length < 1">
            <div class="upload-btn__icon">
              <img src="@/assets/icons/add_photo.svg" />
            </div>
            <h4 class="upload-btn__title">Upload cover image</h4>
            <p class="upload-btn__drag">
              Minimum width 480 pixels, 16:9 recommended
            </p>
          </div>
        </a-upload>
        <div class="create-event-modal-container__body--form">
          <div class="input-field">
              <p class="input-field__label">Description <span>*</span></p>
              <textarea
                v-model="event.description"
                name=""
                id=""
                rows="4"
                class="input-field__textarea"
                placeholder="Ex: topics, schedule, etc."
              ></textarea>
            </div>
          <div class="event-type">
            <p>Event type</p>
            <a-radio-group v-model:value="event.event_type" name="radioGroup">
              <a-radio value="online">Online</a-radio>
              <a-radio value="in_person">In person</a-radio>
            </a-radio-group>
          </div>
          <!-- Show Select Type Online Form -->
          <div v-if="event.event_type === 'online'">
            <div class="input-field">
              <p class="input-field__label">External event link <span>*</span></p>
              <input type="url" v-model="event.link" class="input-field__input" />
               <p v-if="errors.urlError" class="input-field__error">{{ errors.urlError }}</p>
            </div>
            <div class="input-field">
              <p class="input-field__label">Event name <span>*</span></p>
              <input type="text" v-model="event.title" class="input-field__input" />
            </div>
            <div class="input-field">
              <p class="input-field__label">Timezone <span>*</span></p>
              <a-select
                v-model:value="event.timezone"
                class="input-field__select"
                placeholder="Select timezone"
                :options="selectTimeZoneData"
              >
              </a-select>
            </div>
            <a-row :gutter="[18, 0]">
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="input-field">
                  <p class="input-field__label">Start date <span>*</span></p>
                  <a-date-picker v-model:value="startDate" />
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="input-field">
                  <p class="input-field__label">End date <span>*</span></p>
                  <a-date-picker v-model:value="endDate" :disabled="!startDate" :disabled-date="disabledEndDate" />
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="[18, 0]">
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="input-field">
                  <p class="input-field__label">Start time</p>
                  <a-time-picker v-model:value="startTime" format="HH:mm" :disabled="!startDate && !endDate" :disabledHours="disabledStartTime().disabledHours" :disabledMinutes="disabledStartTime().disabledMinutes" />
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="input-field">
                  <p class="input-field__label">End time</p>
                  <a-time-picker v-model:value="endTime" :disabled="!startTime" format="HH:mm" :disabledHours="disableEndTime().disabledHours" :disabledMinutes="disableEndTime().disabledMinutes" />
                </div>
              </a-col>
            </a-row>
            <p v-if="timeError" class="input-field__error">{{ timeError }}</p>
          </div>
          <!-- Show Select Type InPerson Form -->
          <div v-if="event.event_type === 'in_person'">
            <div class="input-field">
              <p class="input-field__label">External event link <span>*</span></p>
              <input type="url" v-model="event.link" class="input-field__input" />
               <p v-if="errors.urlError" class="input-field__error">{{ errors.urlError }}</p>
            </div>
            <div class="input-field">
              <p class="input-field__label">Event name <span>*</span></p>
              <input type="text" v-model="event.title" class="input-field__input" />
            </div>
            <div class="input-field">
              <p class="input-field__label">Timezone <span>*</span></p>
              <a-select
                v-model:value="event.timezone"
                class="input-field__select"
                placeholder="Select timezone"
                :options="selectTimeZoneData"
              >
              </a-select>
            </div>
            <a-row :gutter="[18, 0]">
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="input-field">
                  <p class="input-field__label">Start date <span>*</span></p>
                  <a-date-picker v-model:value="startDate"/>
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="input-field">
                  <p class="input-field__label">End date <span>*</span></p>
                  <a-date-picker v-model:value="endDate" :disabled="!startDate" :disabled-date="disabledEndDate" />
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="[18, 0]">
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="input-field">
                  <p class="input-field__label">Start time </p>
                  <a-time-picker 
                    v-model:value="startTime" 
                    format="HH:mm" 
                     :disabled="!startDate && !endDate"
                    :disabledHours="disabledStartTime().disabledHours" 
                    :disabledMinutes="disabledStartTime().disabledMinutes" 
                  />
                </div>
              </a-col>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="input-field">
                  <p class="input-field__label">End time</p>
                  <a-time-picker 
                      v-model:value="endTime" 
                      :disabled="!startTime" 
                      format="HH:mm" 
                      :disabledHours="disableEndTime().disabledHours" 
                      :disabledMinutes="disableEndTime().disabledMinutes" 
                    />
                </div>
              </a-col>
            </a-row>
            <p v-if="timeError" class="input-field__error">{{ timeError }}</p>
             <!-- <p v-if="dateValidationError">{{ dateValidationError }}</p> -->
            <div class="input-field">
              <p class="input-field__label">Address</p>
              <a-select
                v-model:value="event.address"
                class="input-field__select"
                placeholder="Search address"
                :options="selectAddressData"
                show-search
                filter-option
                :loading="addressLoading"
                @search="handleSearch"
                @change="handleSelectChange"
              >
              </a-select>
            </div>
            <div class="input-field">
              <p class="input-field__label">Venue</p>
              <input
                type="text"
                v-model="event.venue"
                class="input-field__input"
                placeholder="E.g. floor number, room number, etc."
              />
            </div>
            <!-- <div class="input-field">
              <p class="input-field__label">Map</p>
              <GMapMap
                :center="center"
                :zoom="10"
                map-type-id="terrain"
                style="height: 40rem"
              >
                <GMapCluster :zoomOnClick="true">
                  <GMapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center = m.position"
                  />
                </GMapCluster>
              </GMapMap>
            </div> -->
          </div>
        </div>
      </div>
      <div class="create-event-modal-container__footer">
        <a-button :loading="isEventLoading" @click="addEvent" :class="{ 'disable-save-btn': isCreateButtonDisabled }" >{{ selectedEvent ? 'Update' : 'Create' }}</a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
// import { GMapMap, GMapMarker } from '@fawmi/vue-google-maps';
import moment from 'moment';
export default {
  data() {
    return {
      fileListMedia: [],
      isUploading: false,
      isEventLoading:false,
      selectEventType: "online",
      selectEventFormat: null,
      addressLoading:false,
      selectEventFormatData: [
        // { value: "audio_event", label: "Audio Event" },
        // { value: "live", label: "Live" },
        { value: "external_event_link", label: "External event link" },
      ],
      selectTimeZone: null,
      eventId:null,
      errors: {
        eventError: '',
        urlError: '',
        timezone: '',
      },
      // selectTimeZoneData: [
      //   { value: "UTC-12:00", label: "UTC-12:00 International Date Line West" },
      //   {
      //     value: "UTC-11:00",
      //     label: "UTC-11:00 Coordinated Universal Time-11",
      //   },
      //   { value: "UTC-10:00", label: "UTC-10:00 Hawaii" },
      //   { value: "UTC-09:00", label: "UTC-09:00 Alaska" },
      //   { value: "UTC-08:00", label: "UTC-08:00 Pacific Time (US & Canada)" },
      //   { value: "UTC-07:00", label: "UTC-07:00 Mountain Time (US & Canada)" },
      //   { value: "UTC-06:00", label: "UTC-06:00 Central Time (US & Canada)" },
      //   { value: "UTC-05:00", label: "UTC-05:00 Eastern Time (US & Canada)" },
      //   { value: "UTC-04:00", label: "UTC-04:00 Atlantic Time (Canada)" },
      //   { value: "UTC-03:00", label: "UTC-03:00 Brasilia" },
      //   { value: "UTC-02:00", label: "UTC-02:00 Mid-Atlantic" },
      //   { value: "UTC-01:00", label: "UTC-01:00 Azores" },
      // ],
      selectAddress: null,
      image:null,
      timeError:'',
      // selectAddressData: [],
      event: {
        event_type:'online',
        title: '',
        description: '',
        link: '',
        address:'',
        location: '',
        timezone: '',
        latitude: null,
        longitude:null,
        venue: '',
        files: [],
        schedule:[],
      },
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      center: { lat: 51.093048, lng: 6.84212 },
      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
        },
      ],
    };
  },
  // components: { GMapMap, GMapMarker },
  props: {
    visible: Boolean,
    singleEvent: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      selectTimeZoneData: "location/getTimeZones",
      selectAddressData:'location/getLocation'
    }),
    selectedEvent() {
    return this.$store.getters["profile/selectedEvent"]
    },
    isCreateButtonDisabled() {
    if (this.event.event_type === 'online') {
      return (
        this.fileListMedia.length === 0 ||
        this.errors.urlError !== '' ||
        this.event.link === '' ||
        this.event.timezone === '' ||
        this.event.description === '' ||
        this.event.description === null ||
        this.event.title === '' ||
        !this.combinedStartDateTime ||
        !this.combinedEndDateTime
      );
    } else {
      return (
        this.fileListMedia.length === 0 ||
        this.event.description === '' ||
        this.event.description === null ||
        this.event.timezone === '' ||
        this.event.title === '' ||
        this.errors.urlError !== '' ||
        this.event.link === '' ||
        !this.combinedStartDateTime ||
        !this.combinedEndDateTime
      );
    }
  },
  combinedStartDateTime() {
      return this.isValidDateTime(this.startDate, this.startTime)
        ? `${this.startDate.format("YYYY-MM-DD")}${this.startTime ? " "+this.startTime.format("HH:mm") : ''}`
        : "";
    },
    combinedEndDateTime() {
      return this.isValidDateTime(this.endDate, this.endTime)
        ? `${this.endDate.format("YYYY-MM-DD")}${this.endTime ? " "+this.endTime.format("HH:mm") : ''}`
        : "";
    },
    isOnlineEvent(){
      return this.event.event_type === 'online'
    }
  },

  async mounted() {
    await this.getTimeZones(); 
  },
  watch: {
    selectedEvent(newValue, oldValue) {
    if (newValue) {
      // Set event ID and other event properties
      this.eventId = newValue.id;
      this.event.event_type = newValue.event_type;
      this.event.title = newValue.title;
      this.event.description = newValue.description;
      this.event.link = newValue.link;
      this.event.address = newValue.address;
      this.event.location = newValue.location;
      this.event.timezone = newValue.timezone;
      this.event.latitude = newValue.latitude;
      this.event.longitude = newValue.longitude;
      this.event.venue = newValue.venue;
      
      // Set files
      this.event.files = [];
      if (newValue.files && newValue.files.length > 0) {
        this.event.files.push(newValue.files[0].id);
        
        const file = {
          uid: newValue.files[0].id,
          name: 'image.jpg',
          status: 'done',
          url: newValue.files[0].file,
        };
        this.fileListMedia = [file];
      }

      // Set dates and times using moment.js
      const lowerDateTime = moment(newValue.schedule.lower);
      const upperDateTime = moment(newValue.schedule.upper);
      const lowerDate = lowerDateTime.format('YYYY-MM-DD');
      const lowerTime = lowerDateTime.format('HH:mm');       
      const upperDate = upperDateTime.format('YYYY-MM-DD');  
      const upperTime = upperDateTime.format('HH:mm'); 
      this.startDate = moment(lowerDate); 
      this.endDate = moment(upperDate);   
      this.startTime = moment(lowerTime, 'HH:mm'); 
      this.endTime = moment(upperTime, 'HH:mm'); 
    }else{
      this.resetLocalEventData();
    }
  },
      'event.link': function (newLink, oldLink) {
    if (!newLink) {
      // If the link is empty, clear the error message
      this.errors.urlError = '';
    } else {
      // If the link is not empty, validate it
      this.validateUrl(newLink);
    }
  },
      'startTime'(newStartTime) {
        if (newStartTime) {
          const start = moment(newStartTime, 'HH:mm');
          const end = moment(this.endTime, 'HH:mm');
          if (start.isAfter(end)) {
            this.endTime = null;
          }
    }
  },
  'startDate'(newStartDate) {
    if (newStartDate && this.endDate) {
      if (newStartDate > this.endDate) {
        this.endDate = null;
      }
    }
  },
    },

  methods: {
    isValidDateTime(date, time) {
      return moment.isMoment(date) && ((!time && !this.isOnlineEvent) || moment.isMoment(time)) && date.isValid() && ((!time && !this.isOnlineEvent) || time.isValid());
    },
    validateUrl(link) {
    // Updated URL Regex without unnecessary escape characters
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}([/a-zA-Z0-9#?&%=.-]*)?$/;
    
    if (!urlRegex.test(link)) {
      this.errors.urlError = 'Enter a valid URL.';
    } else {
      this.errors.urlError = '';
      // this.event.address = '';
      // this.event.latitude = null;
      // this.event.longitude = null;
    }
   
    },

    async getAllEvents() {
      try {
        this.loadingEvents = true;
        const payload = {
        user_id: this.$route.params.id,
        page: 1,
        page_size: 8,
      };
        await this.$store.dispatch("profile/getAllEvent", {
          user_id:this.$route.params.id,
          payload: payload
        });
      } catch (error) {
        console.log('error',error);
      } finally{
        this.loadingEvents = false;
      }
    },

    closeModal() {
      this.$emit("closeModal");
      this.$store.dispatch('profile/resetEvent');
    },
    beforeUploadMedia(file) {
      return false;
    },
    debouncedSearch: debounce(async function (value) {
      if (value) {
        try {
          // Dispatch the search action with the current search input
          await this.$store.dispatch('location/getLocation', value);
        } catch (error) {
          console.error('Error during search:', error);
        } finally {
          this.addressLoading = false; // Hide loader after API call completes
        }
      } else {
        // Clear options or handle empty search
        this.$store.commit('location/setLocation', []);
        this.addressLoading = false; // Ensure loader is hidden if no value
      }
    }, 3000),
    handleSearch(value) {
      this.addressLoading = true;
      this.debouncedSearch(value);
    },
    resetLocalEventData() {
    this.event = {
      event_type: 'online',
      title: null,
      description: null,
      link: null,
      address: null,
      venue: null,
      latitude: null,
      longitude: null,
      location: null,
      timezone: null,
      files: [],
      schedule: []
    };
    this.eventId=null;
    this.startDate = null;
    this.startTime = null;
    this.endDate = null;
    this.endTime = null;
    this.fileListMedia = [];
    this.combinedStartDateTime = '';
    this.combinedEndDateTime = '';
    },
    disabledStartTime() {
      if (!this.startDate) {
        return {
          disabledHours: () => [],
          disabledMinutes: () => []
        };
      }

      const now = moment();
      const currentHour = now.hour();
      const currentMinute = now.minute();
      const isToday = moment(this.startDate).isSame(now, 'day');

      const disabledHours = () => {
        if (!isToday) return []; // Allow all hours if not today
        return Array.from({ length: currentHour }, (_, i) => i);
      };

      const disabledMinutes = (selectedHour) => {
        if (!isToday || selectedHour < currentHour) return []; // Allow all minutes if not today or hour is before current
        if (selectedHour > currentHour) return []; // Allow all minutes if hour is after current hour
        return Array.from({ length: currentMinute }, (_, i) => i);
      };

      return {
        disabledHours,
        disabledMinutes,
      };
    },
    disableEndTime() {
    if (!this.startDate || !this.startTime) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => []
      };
    }
    const start = moment(this.startTime, 'HH:mm');
    const startHour = start.hour();
    const startMinute = start.minute();
    const isToday = moment(this.startDate).isSame(moment(), 'day');

    const disabledHours = () => {
      const isEventEndingToday = moment(this.endDate).isSame(moment(), 'day');
      if (!isEventEndingToday) return [];
      return Array.from({ length: startHour }, (_, i) => i);
    };

    const disabledMinutes = (selectedHour) => {
      const isEventEndingToday = moment(this.endDate).isSame(moment(), 'day');
      if (!isEventEndingToday || selectedHour < startHour) return [];
      if (selectedHour > startHour) return [];
      return Array.from({ length: 60 }, (_, i) => i).filter(minute => minute <= startMinute);
    };

    return {
      disabledHours,
      disabledMinutes,
    };
    },

    disabledStartDate(current) {
      const today = moment().startOf('day');
      return current && current < today;
    },

    disabledEndDate(current) {
      if (!this.startDate) {
        return false;
      }
      const start = moment(this.startDate).startOf('day');
      return current && current < start;
    },
    getImage(info){
      this.image=info;
    },
    async handleChangeMedia() {
      this.isUploading = true; // Set flag to true
      try {
        this.file = this.image.file;
        const isAcceptableFileType =
        this.image.file.type === "image/jpeg" ||
        this.image.file.type === "image/png" ||
        this.image.file.type === "image/jpg" ||
        this.image.file.type === "image/webp" ||
        this.image.file.type === "image/svg+xml"

        if (isAcceptableFileType) {
            const files = this.image.fileList;
            const form = new FormData();
            files.forEach((val) => {
              form.append("file", val.originFileObj);
            });

            if (this.image.fileList.length > 0) {
              const response = await this.$store.dispatch(
                "feed/uploadFeedPhotoMedia",
                form
              );
              this.event.files=[];
              this.event.files.push(response.data.id);
            }
        }
      } catch (error) {
        console.error("Upload failed", error);
      } finally {
        this.isUploading = false; // Reset flag after completion
      }
    },
    async addEvent() {
  try {
    // Handle event link for online events
    if (this.event.event_type === 'online' || this.event.event_type === 'in_person') {
      let link = this.event.link;
      if (!/^https?:\/\//i.test(link)) {
        link = `https://${link}`;
        this.event.link = link; // Update the link in the event object
      }
    }

    // Clear address and venue based on event type
    if (this.event.event_type === 'online') {
      this.event.address = '';
      this.event.venue = '';
    }

    // Handle scheduling
    if (this.combinedStartDateTime && this.combinedEndDateTime) {
      this.event.schedule = [this.combinedStartDateTime, this.combinedEndDateTime];
      this.combinedStartDateTime = '';
      this.combinedEndDateTime = '';
    }

    this.isEventLoading = true;
    this.timeError='';
    // Determine if a new image was uploaded
    const existingFileId = this.event.files.length > 0 ? this.event.files[0] : null;
    const newFileUploaded = this.fileListMedia.length > 0 && 
                            (!existingFileId || this.fileListMedia[0].uid !== existingFileId);

    // Upload media if a new image was uploaded
    if (newFileUploaded) {
      await this.handleChangeMedia();
    }

    // Update or Add the event
    if (this.eventId) {
      const res = await this.$store.dispatch("profile/updateEvent", {
        payload: this.event,
        eventId: this.eventId
      });

      if (res.status === 200) {
        await this.getAllEvents();
        this.closeModal();
        this.resetLocalEventData();
        this.$showToast({message:'Event details updated successfully.', type:'success'})
      }
    } else {
      console.lo
      const res = await this.$store.dispatch("profile/addEvent", this.event);
      if (res.status === 201) {
        await this.getAllEvents();
        this.closeModal();
        this.resetLocalEventData();
        this.$showToast({message:'Event added successfully.', type:'success'})
      }
    }
  } catch (error) {
    if(error.response.data.schedule){
      this.timeError = error.response.data.schedule[0];
    }else{
      this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
      // console.log("Error adding event:", error.response?.data);
    }
  } finally {
    this.isEventLoading = false;
  }
    },
    handleSelectChange(value) {
      const selectedOption = this.selectAddressData.find(option => option.value === value);
       if (selectedOption) {
         this.event.latitude = selectedOption.lat;
         this.event.longitude = selectedOption.lon;
         this.markers = [{ position: { lat: selectedOption.lat, lng: selectedOption.lng } }];
      }
    },
    async getTimeZones() {
      try {
         const res = await this.$store.dispatch("location/getTimeZones");
      } catch (error) {
        console.log('error',error);
      }
    },
    async getLocation() {
      try {
        const res = await this.$store.dispatch("location/getLocation");
      } catch (error) {
        console.log('error',error);
      }
    },
  },
};
</script>
<style lang="scss">
 .invalid-link {
    font-size: 2.2rem;
    font-family: $font-secondary-bold;
  }
.create-event-modal {
  width: 60rem !important;
  top: 4rem !important;
  .ant-modal-content {
    box-shadow: 0px 0px 94px #00000047;
    .ant-modal-header {
      padding: 2rem 2.4rem;
      margin-bottom: 0;
      background-color: transparent;
      border-bottom: 1px solid $color-dark-grey-5;
      .ant-modal-title {
        font-size: 2.4rem;
        line-height: 2.6rem;
        font-family: $font-primary-bold;
      }
    }
    .ant-modal-close {
      margin: 1.8rem 1.6rem 0 0;
    }
    .ant-modal-body {
      .create-event-modal-container {
        border-radius: 1rem;
        &__header {
          padding: 2.5rem;
          &--title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            line-height: normal;
            margin-bottom: 0;
            color: $color-black;
          }
        }
        &__body {
          padding: 0;
          max-height: calc(100vh - 24rem);
          overflow-y: auto;
          .ant-upload-picture-card-wrapper {
            .ant-upload-list {
              .ant-upload-list-picture-card-container {
                width: 100%;
                height: 100%;
                margin: 0 auto;
                .ant-upload-list-item {
                  width: 100%;
                  height: 25rem;
                  margin: 0;
                  padding: 0;
                  border: none !important;
                  .ant-upload-list-item-info {
                    width: 100%;
                    height: 100%;
                    .ant-upload-list-item-name {
                      color: $color-black;
                      font-size: 1.6rem;
                      line-height: 2.2rem;
                      opacity: 0.7;
                    }
                  }
                  .ant-upload-list-item-actions {
                    pointer-events: all;
                    // z-index: 1;
                    a {
                      display: none;
                    }
                    .anticon-delete {
                      font-size: 3rem;
                    }
                  }
                }
              }
            }
            .ant-upload-select {
              margin: 0;
              width: 100%;
              border: none;
              .ant-upload {
                padding: 4rem 2rem;
                min-height: 25rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-light-grey-2;
                .upload-btn {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  &__icon {
                    background-color: rgb(228, 230, 235);
                    height: 5rem;
                    width: 5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    margin-bottom: 1rem;
                    img {
                      width: 3rem;
                    }
                  }
                  &__title {
                    font-size: 1.7rem;
                    font-family: $font-primary-medium;
                    line-height: normal;
                    margin-bottom: 0.5rem;
                    color: $color-black;
                  }
                  &__drag {
                    font-size: 1.4rem;
                    font-family: $font-primary;
                    line-height: normal;
                    margin-bottom: 0;
                    color: $color-black;
                    opacity: 0.6;
                  }
                }
              }
              &:hover {
                border-color: $color-faded-blue;
              }
            }
            .ant-upload-list-picture-card {
              .ant-upload-list-picture-card-container {
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-upload-list-item-uploading {
                  width: 100%;
                  margin: 0 auto 1rem;
                  display: block;
                  .ant-upload-list-item-info {
                    span {
                      width: 15rem;
                      .ant-upload-list-item-uploading-text {
                        font-size: 1.4rem;
                        font-family: $font-primary;
                        line-height: normal;
                        margin-bottom: 0;
                        color: $color-black;
                        opacity: 0.6;
                      }
                    }
                  }
                  .ant-upload-list-item-progress {
                    .ant-progress {
                      div {
                        .ant-progress-outer {
                          .ant-progress-inner {
                            .ant-progress-bg {
                              background-color: $color-primary;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &--form {
            padding: 2rem 2.4rem;
            .event-type {
              margin-bottom: 2.5rem;
              p {
                font-size: 1.6rem;
                line-height: 2rem;
                font-family: $font-primary;
                margin-bottom: 1.2rem;
              }
              .ant-radio-group {
                display: flex;
                align-items: center;
                gap: 1rem;
                .ant-radio-wrapper {
                  margin-right: 0;
                  font-size: 1.4rem;
                  color: $color-black;
                  font-family: $font-primary;
                  display: flex;
                  align-items: center;
                  .ant-radio {
                    display: inherit;
                    .ant-radio-inner {
                      border: 0.2rem solid $color-black;
                      height: 2rem;
                      width: 2rem;
                      &::after {
                        background-color: $color-primary;
                        width: 1rem;
                        height: 1rem;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      }
                    }
                  }
                  .ant-radio-checked {
                    .ant-radio-inner {
                      border: 0.2rem solid $color-black;
                      &::after {
                        background-color: $color-primary;
                      }
                    }
                  }
                }
              }
            }
            .input-field {
              margin-bottom: 2rem;
              &__label {
                font-size: 1.6rem;
                line-height: 2rem;
                font-family: $font-primary;
                margin-bottom: 1.2rem;
                span {
                  color: red;
                }
              }
              &__textarea {
                border: 1px solid $color-dark-grey-5;
                padding: 1rem 1.4rem;
                height: 8.3rem;
                border-radius: 7px !important;
                outline: none;
                font-size: 1.4rem;
                font-family: $font-primary;
                width: 100%;
                color: $color-black;
                resize: none;
                &::placeholder {
                  color: $color-black;
                  opacity: 0.6;
                }
                &::-webkit-scrollbar {
                  width: 0.4rem;
                  height: 0.4rem;
                }

                &::-webkit-scrollbar-track {
                  background-color: transparent;
                  border-radius: 0.8rem;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: $color-primary;
                  border-radius: 0.8rem;
                  width: 0.4rem;
                  height: 0.4rem;
                }
              }
              &__input {
                border: 1px solid $color-dark-grey-5;
                padding: 0 1.4rem;
                height: 4rem;
                border-radius: 7px !important;
                outline: none;
                font-size: 1.4rem;
                font-family: $font-primary;
                width: 100%;
                color: $color-black;
                &::placeholder {
                  color: $color-black;
                  opacity: 0.6;
                }
              }
              &__select {
                height: 4rem;
                width: 100%;
                border: 1px solid $color-dark-grey-5;
                border-radius: 7px !important;
                .ant-select-selector {
                  height: 100% !important;
                  border: none;
                  background-color: transparent;
                  box-shadow: none;
                  font-size: 1.4rem;
                  font-family: $font-primary;
                  padding: 0 1.4rem;
                  .ant-select-selection-search {
                    .ant-select-selection-search-input {
                      height: 100%;
                    }
                  }
                  .ant-select-selection-item {
                    font-size: 1.4rem;
                    font-family: $font-primary;
                  }
                  .ant-select-selection-placeholder {
                    color: $color-black;
                    opacity: 0.6;
                  }
                }
                .ant-select-arrow {
                  right: 1.5rem;
                  width: 1rem;
                  .anticon {
                    font-size: 1rem;
                    svg {
                      fill: $color-black;
                    }
                  }
                }
              }
              .ant-calendar-picker,
              .ant-time-picker {
                width: 100%;
                background-color: $color-white;
                .ant-calendar-picker-input,
                .ant-time-picker-input {
                  font-size: 1.4rem;
                  font-family: $font-primary;
                  padding: 1rem 1.4rem;
                  outline: none;
                  border: 1px solid $color-dark-grey-5;
                  margin-bottom: 0;
                  box-shadow: none;
                  &::placeholder {
                    color: $color-black;
                    opacity: 0.6;
                  }
                }
                .ant-time-picker-icon {
                  right: 1.4rem;
                }
                .anticon {
                  right: 1.4rem;
                  color: $color-black;
                  opacity: 0.6;
                }
              }
              &__error {
                font-size: 1.6rem;
                line-height: 2rem;
                font-family: $font-primary;
                color: red;
                margin-top: 0.5rem;
              }
            }
          }

          &::-webkit-scrollbar {
            width: 0.4rem;
            height: 0.4rem;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 0.8rem;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 0.8rem;
            width: 0.4rem;
            height: 0.4rem;
          }
        }
        &__footer {
          border-top: 1px solid $color-dark-grey-5;
          padding: 2rem 2.4rem;
          .ant-btn {
            width: 10rem;
            background-color: $color-primary;
            border: none;
            outline: none;
            height: 4rem;
            font-size: 1.4rem;
            font-family: $font-primary-medium !important;
            color: $color-white;
            margin-left: auto;
            display: block;
            span {
              line-height: normal;
            }
            .anticon {
              .anticon-spin {
                font-size: 1.8rem;
                color: $color-primary;
              }
            }
            &:hover,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
          }
          .disable-save-btn {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>
