<template>
  <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <div class="connection-item-container-v2">
      <a-dropdown
        v-if="isLoggedInUser && (selectedGroupName !== 'recommend' && selectedGroupName !== 'Requested Invitations')"
        :trigger="['click']"
        placement="bottomRight"
        class="connection-item-container-v2__dropdown"
      >
        <a class="ant-dropdown-link">
          <i class="icon icon-vertical_three-dots"></i>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item
              v-if="isInConnection && !isFollowed && !isFriendRequestPending"
              @click.prevent="showAddConnectionToGroup()"
              key="0"
            >
              <a>Add to Group</a>
            </a-menu-item>
            <a-menu-item v-if="(isInConnection || isFollowed) && !isFriendRequestPending" key="2">
              <a class="remove-btn" @click="showRemoveContactModal()"
                >{{isFollowed  ? 'Unfollow' :  'Remove Connection' }}</a
              >
            </a-menu-item>
            <a-menu-item v-if="isInGroup && !isFollowed && !isFriendRequestPending" key="3">
              <a class="remove-btn" @click="visibleRemoveModal()"
                >Remove from group</a
              >
            </a-menu-item>
            <a-menu-item v-if="isFriendRequestPending" key="3">
              <a class="remove-btn" @click="toggleCancelRequestModal()"
                >Cancel Request</a
              >
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <div class="connection-item-container-v2__body">
        <img
          :key="imgUrl"
          loading="lazy"
          :src="imgUrl"
          alt=""
          class="connection-item-container-v2__body--profile-image"
          @click="redirectToUser(user)"
        />
        <h1
          class="connection-item-container-v2__body--name"
          @click="redirectToUser(user)"
        >
          {{ name }}
        </h1>
        <p class="connection-item-container-v2__body--postition" @click="redirectToUser(user)">
          <template v-if="jobTitle">{{ jobTitle }}</template>
        </p>
        <div class="connection-item-container-v2__body--mutual-friends" v-if="mutualFriends">
          <img :src="mutualFriendsImage" alt="Image"/>
          <p @click="redirectToUser(user)">
            {{ mutualFriends }}
          </p>
        </div>
      </div>
      <div class="connection-item-container-v2__footer">
          <a-button class="accept-btn" v-if="selectedGroupName === 'Requested Invitations'" @click="acceptFriendRequest()" :loading="loader" :disabled="loader">Accept</a-button>
          <a-button class="decline-btn" v-if="selectedGroupName === 'Requested Invitations'" @click="declineFriendRequest()" :loading="declineRequestLoader" :disabled="declineRequestLoader">Decline</a-button>
        </div>
    </div>
  </a-col>

  <!-- for connection remove -->
  <delete-modal
    :visible="visibleRemoveContactModal"
    @handle-close="visibleRemoveContactModal = false"
    :title="isFollowed ? 'Are you sure you want to unfollow them?' : 'Are you sure you want to remove this?'"
    :confirmText="isFollowed ? 'Yes, Unfollow' : 'Yes, Remove'"
    :cancelText="isFollowed ? 'No, Don\'t Unfollow' : 'No, Don\'t Remove'"
    :loading="loader"
    @delete-record="removeContact"
  ></delete-modal>

  <!-- For group audience remove -->
  <delete-modal
    :visible="visibleConfirmationModal"
    @handle-close="visibleConfirmationModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="removeFromGroup"
  ></delete-modal>

  <delete-modal
    :visible="openCancelRequestModal"
    @handle-close="openCancelRequestModal = false"
    title="Are You Sure You Want To Cancel This Request?"
    confirmText="Yes, Cancel"
    cancelText="No, Don't Cancel"
    :loading="loader"
    @delete-record="cancelRequest"
  ></delete-modal>
</template>

<script>
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import { mapActions } from "vuex";
export default {
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    user: {
      type: Object,
      default: ()=>({})
    },
    name: String,
    organization: String,
    jobTitle: String,
    imgUrl: String,
    connections: Object,
    isInGroup: Boolean,
    isInConnection: Boolean,
    groupId: String,
    xs: Number,
    sm: Number,
    md: Number,
    lg: Number,
    xl: Number,
    isLoggedInUser: Boolean,
    mutualFriends: String,
    mutualFriendsImage: String,
    selectedGroupName:{
      type: String,
      default: () => ('')
    }
  },
  components: {
    DeleteModal,
  },
  emits: ["show-add-connections-category-modal"],
  computed: {
    connectionsInGroup() {
      return this.$store.getters["profile/getConnectionsInGroup"];
    },
    isFollowed(){
      return this.selectedGroupName === 'Organizations Followed' || this.selectedGroupName === 'Contacts Followed'
    },
    isFriendRequestPending(){
      return this.selectedGroupName === 'Pending Invitations'
    },
  },
  data() {
    return {
      visibleConfirmationModal: false,
      visibleRemoveContactModal: false,
      loader: false,
      openCancelRequestModal: false,
      declineRequestLoader: false
    };
  },
  methods: {
    ...mapActions({
      setActiveTabKey: 'profile/setActiveTabKey',
      setSelectedUser:'profile/setSelectedUser'
    }),
    showRemoveContactModal() {
      this.visibleRemoveContactModal = true;
    },
    visibleRemoveModal() {
      this.visibleConfirmationModal = true;
    },
    async getAllGroups() {
      try {
        const payload = {
          user_id: this.$route.params.id,
        };
        // await this.$store.dispatch("profile/loadGroups", payload);
      } catch (err) {
        console.log(err);
      }
    },
    async removeFromGroup() {
      this.loader = true;
      try {
        const payload = {
          id: this.id,
          group: this.groupId,
        };
        const response = await this.$store.dispatch(
          "profile/deleteGroupAudience",
          payload
        );
        if (response.status == 204) {
          await this.getAllGroups();
          this.visibleConfirmationModal = false;
          this.$store.dispatch("profile/fetchGroupAudience", this.groupId);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loader = false;
      }
    },
    async removeContact() {
      try{
        if (this.isFollowed){
          this.loader = true;
          const payload = {
            add_contributors: [],
            remove_contributors: [this.user?.id]
          }
          await this.$store.dispatch("feed/addContributor", payload);
          this.$emit('unfollow', this.user) // emitting so that parent can update the followed organization of followed contacts array
        }
        else{ 
          this.loader = true;
          await this.$store.dispatch("profile/removeUser", {id:this.user?.id});
        }
      }
      catch(err){
        console.log(err)
      }finally{
        this.loader = false;
        this.visibleRemoveContactModal = false;
      }
    },
    showAddConnectionToGroup() {
      this.$emit("show-add-connections-category-modal");
    },
    redirectToUser(user) {
      this.setActiveTabKey('experience')
      this.setSelectedUser(user)
      this.$router.push({
        path: `/user/${user.username}/`,
      });
      // this.getUserInformation();
      // Scroll to top of the page
      window.scrollTo(0, 0);
    },
    async getUserInformation() {
      try {
        await this.$store.dispatch("profile/fetchUserProfile");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        await this.$store.dispatch("profile/fetchPhotoResume");
      } catch (err) {
        console.log(err);
      }
    },
    async cancelRequest(){
      try{
        this.loader=true
        await this.$store.dispatch("profile/handleCancelRequest",{id: this.user?.id});
        this.toggleCancelRequestModal()
        this.$showToast({message:'Request canceled successfully.', type: 'success'})
        this.$emit('filterPendingUser', this.user)
      }
      catch(err){
        console.log(err)
        this.toggleCancelRequestModal()
        this.$showToast({message:'Something went wrong. Please try again later.', type: 'failure'})
      }
      finally{
        this.loader=false
      }
    },
    toggleCancelRequestModal(){
      this.openCancelRequestModal = !this.openCancelRequestModal
    },
    async acceptFriendRequest(){
      try{
        this.loader = true
        let payload = {
        action_type: 'accepted',
        from_user: this.user?.id,
      };
      await this.$store.dispatch(
          "profile/userRequest",
          payload
      )
      await this.$store.dispatch("profile/getFriendsList");
      this.$showToast({message:'Invitation accepted successfully.', type:'success'})
      this.$emit('filterRequestedInvitationUser', this.user)
      }
      catch(err){
        console.log(err)
      }
      finally{
        this.loader = false
      }
    },
    async declineFriendRequest(){
      try{
        this.declineRequestLoader = true
        let payload = {
        action_type: 'rejected',
        from_user: this.user?.id,
      };
      await this.$store.dispatch(
          "profile/userRequest",
          payload
      )
      this.$showToast({message:'Invitation declined successfully.', type:'success'})
      this.$emit('filterRequestedInvitationUser', this.user)
      }
      catch(err){
        console.log(err)
      }
      finally{
        this.declineRequestLoader = false
      }
    }
  },
};
</script>

<style lang="scss">
.connection-item-container-v2 {
  border-radius: 1rem;
  border: 1px solid rgb(214, 214, 214);
  position: relative;
  padding: 3rem;
  min-height: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  flex-direction: column;
  &__dropdown {
    position: absolute;
    right: 2rem;
    top: 2.5rem;
    .icon {
      color: $color-black;
      font-size: 1.8rem;
    }
  }
  &__body {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    &--profile-image {
      border-radius: 100%;
      height: 10rem;
      width: 10rem;
      margin-bottom: 1.5rem;
      cursor: pointer;
      object-fit: fill;
      background-color: rgba(128, 128, 128, 0.452);
    }
    &--name {
      font-size: 2rem;
      line-height: 2.4rem;
      font-family: $font-primary-medium;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-bottom: 1rem;
      cursor: pointer;
      text-align: center;
    }
    &--postition {
      font-size: 1.6rem;
      line-height: 2rem;
      font-family: $font-primary;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 2rem;
      text-align: center;
      cursor: pointer;
    }
    &--mutual-friends {
      display: flex;
      align-items: center;
      margin-top: auto;
      gap: 1rem;
      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 100%;
      }
      p {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-family: $font-primary;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    .ant-btn {
      width: 50%;
      background-color: $color-primary;
      border: none;
      outline: none;
      height: 3.2rem;
      font-size: 1.2rem;
      font-family: $font-primary-medium !important;
      color: $color-white;
      padding: 0 !important;
      span {
        line-height: normal;
      }

      .anticon {
        .anticon-spin {
          font-size: 1.6rem !important;
          color: $color-primary !important;
        }
      }

      &:hover,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
    }
    .decline-btn {
      background-color: #e7e7e7;
      color: $color-black;
      .anticon {
        .anticon-spin {
          color: $color-black !important;
        }
      }
      &:hover,
      &:focus,
      &:active {
        background-color: #e7e7e7;
        color: $color-black;
      }
    }
  }
}
</style>
