<template>
  <div class="education-section">
    <div class="education-section__header">
      <h2 class="education-section__header--title">Education</h2>
      <button
        class="education-section__header--btn"
        v-if="isLoggedInUser && !isSharedProfileView"
        @click="addEducation()"
        id="addEducation"
        title="addEducation"
      >
        <i class="icon icon-add_plus"></i>
      </button>
    </div>
    <div class="education-section__body">
      <template v-if="loadingCertificates">
        <div v-for="loaders in 3"
        :key="loaders">
          <ExperienceSkeletonLoader/>
        </div>
      </template>
      
      <template v-if="certificates.length > 0">
        <!-- <div v-for="(certificate, index) in certificates" :key="index">
          <p>{{ certificate }}</p>
        </div> -->
        <div
        class="education-section__body--education"
        v-for="(certificate, index) in certificates"
        :key="index"
      >
        <div class="header">
          <div class="header__details">
            <h2>{{ certificate.issuing_organization }}</h2>
            <p>
              {{ certificate.title }} {{ certificate?.field_of_study ? `in ${certificate?.field_of_study}` : ''  }} 
              {{ " | " + formattedDate(certificate.issue_date) }} - 
              {{ certificate.expire_date ? formattedDate(certificate.expire_date) : "Present" }}  
            </p>
          </div>
          <div class="header__edit-delete-btn">
            <button
              v-if="isLoggedInUser && !isSharedProfileView" 
              @click="showDeleteModal(certificate.id)"
              class="btn delete-btn"
              id="educationDeleteBtn"
              title="educationDeleteBtn"
            >
              <i class="icon icon-dustbin"></i>
            </button>
            <button
              v-if="isLoggedInUser && !isSharedProfileView"
              @click="editEducation(certificate)"
              class="btn edit-btn"
              id="educationEditBtn"
              title="educationEditBtn"
            >
              <img src="@/assets/icons/edit-icon.svg" width="auto" height="auto" alt="Edit"/>
            </button>
          </div>
        </div>
        <!-- <div class="body">
          <ul v-if="certificate.credential_url">
            <li>
              <a :href="certificate.credential_url" target="_blank">{{ certificate.credential_url }}</a>
            </li>
          </ul>
        </div> -->
      </div>
      </template>
      <p class="message" v-else>
        No record added
      </p>
    </div>
  </div>
  <delete-modal
    :visible="deleteModalVisible"
    @handle-close="deleteModalVisible = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :message="null"
    @delete-record="deleteExperience"
    :loading="deleteloading"
  ></delete-modal>
  <add-certificate-modal
    ref="certificate-modal"
    :action="action"
    :visible="showAddCertificateModal"
    @close-modal="()=>{showAddCertificateModal = false; editCertificateDetails={} }"
    :footer="null"
    :editCertificateDetails="editCertificateDetails"
  ></add-certificate-modal>
</template>

<script>
import AddCertificateModal from "../ProfilePageComponents/AddCertificateModal.vue";
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import ExperienceSkeletonLoader from "./SkeletonLoaders/ExperienceSkeletonLoader.vue";
import moment from 'moment';
import {mapGetters} from "vuex"
export default {
  data() {
    return {
      deleteloading: false,
      deleteModalVisible: false,
      showMessageModal: false,
      action: "",
      message: {},
      showAddCertificateModal: false,
      editCertificateDetails:{},
      loadingCertificates: false
    };
  },
  components: {
    DeleteModal,
    AddCertificateModal,
    ExperienceSkeletonLoader
  },
  async mounted() {
    this.getCertificates();
  },
  computed: {
    ...mapGetters({
      isSharedProfileView: 'profile/isSharedProfileView'
    }),
    //   isExperiencecLoading() {
    //     return this.$store.getters["profile/getExperienceSkeletonLoader"];
    //   },
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
    certificates() {
      return this.$store.getters["profile/getCertificates"];
    },
  },
  methods: {
    async getCertificates() {
      try {
        const payload = {
          user_id: this.$route.params.id,
        };
        this.loadingCertificates = true;
        await this.$store.dispatch("profile/loadCertificates", payload);
        this.loadingCertificates = false;
      } catch (err) {
        console.log(err);
      }
    },
    addEducation() {
      this.action = "add";
      this.showAddCertificateModal = true;
    },
    editEducation(education) {
      this.action = "edit";
      this.editCertificateDetails = education
      this.showAddCertificateModal = true;
      // this.$refs["experience-modal"].selectExperience(experience);
    },
      async deleteExperience() {
        this.deleteloading = true;
        try {
          const response = await this.$store.dispatch(
            "profile/deleteCertificate",
            this.selectedRecordId
          );
          if (response.status === 204) {
            this.$showToast({message:'Education record removed successfully.', type:'success'})
            // this.message = {
            //   title: "Removed successfully.",
            //   desc: "",
            //   type: "success",
            // };
            this.deleteModalVisible = false;
            // this.showMessageModal = true;
          }
        } catch (err) {
          this.$showToast({message:'Something went wrong. Please try again later.', type:'failure'})
          // this.message = {
          //   title: "Something went wrong",
          //   desc: "Please Try Again later. Sorry for the inconvenience",
          //   type: "failure",
          // };
          // this.showMessageModal = true;
          this.deleteloading = false;
        }
        this.selectedRecordId = null;
        this.deleteloading = false;
      },
      showDeleteModal(id) {
        this.selectedRecordId = id;
        this.deleteModalVisible = true;
        this.deleteloading = false;
      },
      formattedDate(date) {
        return moment(date).format('MMM YYYY');
    },
  },
};
</script>

<style lang="scss">
.education-section {
  padding: 3rem 2.6rem;
  border-top: 1px solid $color-dark-grey-5;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.7rem;
    &--title {
      font-size: 2rem;
      font-family: $font-primary-medium;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      @include respond(tab-port) {
        font-size: 2.2rem;
        line-height: 2.8rem;
      }
    }
    &--btn {
      background-color: $color-primary;
      height: 4.2rem;
      width: 4.2rem;
      border: none;
      outline: none;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon {
        color: $color-white;
        font-size: 1.6rem;
      }
    }
  }
  &__body {
    &--education {
      padding: 2.4rem 0 0;
      .header {
        display: flex;
        align-items: flex-start;
        &__details {
          width: 100%;
          padding-right: 2rem;
          h2 {
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            line-height: 1.8rem;
            color: $color-black;
            margin-bottom: 1rem;
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
          p {
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 1.8rem;
            color: #6f6f6f;
            @include respond(tab-port) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
        }
        &__edit-delete-btn {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          .btn {
            line-height: normal;
            width: fit-content;
            height: fit-content;
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            img {
              width: 2.5rem;
              height: auto;
            }
            .icon {
              color: $color-black;
              font-size: 1.8rem;
              display: block;
            }
            @include respond(tab-port) {
              img {
                width: 3rem;
              }
              .icon {
                font-size: 2.3rem;
              }
            }
          }
        }
      }
      .body {
        ul {
          padding-left: 1.4rem;
          margin-top: 1.5rem;
          margin-bottom: 2rem;
          li {
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-bottom: 0;
            line-height: 1.9rem;
            color: $color-black;
            padding-left: 0;
            max-width: 90%;
            a {
                color: $color-primary;
            }
          }
        }
      }
    }
    .message {
      font-size: 1.6rem;
      font-family: $font-primary;
      margin-bottom: 0;
      line-height: 2.3rem;
      color: $color-black;
      margin-bottom: 3rem;
    }
  }
}
</style>
