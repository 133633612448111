<template>
  <div class="profile-upload-modal">
    <div v-show="imageUrl === ''" class="default-image">
      <a-upload
        name="avatar"
        list-type="picture-card"
        :file-list="fileList"
        class="avatar-uploader"
        :before-upload="beforeUpload"
        :accept="'.JPEG , .TIFF , .PNG , .Raw, .JPG'"
        @change="handleUpload"
      >
        <img src="../../assets/icons/add_photo.png" alt="" />
        <p>Add Photo</p>
      </a-upload>
    </div>

    <div class="content">
      <section class="cropper-area">
        <div class="img-cropper">
          <vue-cropper
            v-if="imageUrl"
            ref="cropper"
            :aspect-ratio="verRatio / horRatio"
            :src="imageUrl"
            :movable="true"
            :crop-box-movable="true"
            :crop-box-resizable="true"
            :zoom-on-wheel="false"
            preview=".preview"
          />
        </div>
      </section>
    </div>

    <div class="btns"  v-if="imageUrl" >
      <button 
      class="btn-cancel"
      @click="cancel"
      >
        Cancel
      </button>
      <button
        :disabled="loading"
        class="btn-upload"
        @click="uploadPhoto"
      >
        Save
        <div v-if="loading" class="loader">
          <the-loader></the-loader>
        </div>
      </button>
      
    </div>
  </div>
</template>

<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import TheLoader from "../BaseComponents/TheLoader.vue";

export default {
  components: {
    VueCropper,
    TheLoader,
  },
  emits: ["upload-photo"],
  props:{verRatio: Number , horRatio: Number , loading: Boolean},
  data() {
    return {
      file: null,
      imageUrl: "",
      cropImg: "",
      fileType: '',
      fileName: '',
      fileList: []
    };
  },
  watch:{
    loading:{
      handler(val){
        if (!val){
          this.imageUrl = ""
          this.cropImg = ""
        }
      }
    }
  },
  methods: {
    beforeUpload(file) {
      this.file = file;
      this.fileType = this.file.type
      this.fileName = this.file.name
      return false;
    },
    handleUpload() {
      getBase64(this.file, (imageUrl) => {
        this.imageUrl = imageUrl;
      });
    },
    cancel(){
      this.imageUrl = ''
    },
    uploadPhoto() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$emit("upload-photo", this.cropImg , this.fileType , this.fileName, this.imageUrl);
    },
  },
};
</script>

<style lang="scss">
.profile-upload-modal {
  padding: 4rem 3rem;
  .btns {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-top: 3rem;
    border-top:1px solid #f0f0f0;
    padding-top: 3rem;
    .ant-upload-picture-card-wrapper {
      width: fit-content;
      .ant-upload {
        width: fit-content;
        height: fit-content;
        padding: 0;
        border: none;
        margin: 0;
        .save-choose {
          margin: 0 1rem;
          padding: 0.5rem 2rem;
          cursor: pointer;
          font-size: 1.6rem;
          font-family: $font-secondary;
          background-color: $color-primary;
          color: $color-white;
          border: none;
        }
      }
    }
    button {
      height: fit-content;
      padding: 0.8rem 3rem;
      cursor: pointer;
      font-size: 1.6rem;
      font-family: $font-primary-medium;
      border: none;
      background-color: #80808073;
    }
    .btn-upload {
      background-color: $color-primary;
      color: $color-white;
      position: relative;
      margin-left: 2rem;
      .loader {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0;
        background-color: rgba(255, 255, 255, 0.527);
        .ant-spin {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: initial;
          .anticon-loading {
            height: fit-content;
            .anticon-spin {
              width: 3rem;
              height: 3rem;
              color: $color-white;
            }
          }
        }
      }
    }
  }
  .default-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    .ant-upload-picture-card-wrapper {
      width: fit-content;
      .ant-upload-select {
        margin: 0;
        padding: 0;
        width: 18rem;
        height: 15rem;
        opacity: 0.7;
      }
    }
    p {
      font-size: 1.6rem;
      font-family: $font-secondary;
      margin-bottom: 0;
    }
    img {
      width: 25%;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
